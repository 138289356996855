import themeColors from '@/assets/theme/colors'

const classes = {
  mainBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px'
  },
  linkBox: {
    textDecoration: 'none',
    fontWeight: 800,
    color: themeColors.gray.dark,
    '&:hover': {
      opacity: 0.7
    },
    '&:active': {
      opacity: 0.85
    }
  },
  separator: {
    fontSize: 13,
    fontWeight: 800,
    marginLeft: '15px',
    marginRight: '15px'

    // height:      23,
  }
}

export default classes
