// React, Next
import { useTranslation } from 'next-i18next'
import { useContext } from 'react'

// MUI
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

// Next
import Link from 'next/link'
import { useRouter } from 'next/router'
import nextI18nextConfig from 'next-i18next.config'
import { translateHref } from '@/assets/functions/links'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'

// Style
import breakpoints from 'assets/theme/breakpoints'
import Text from 'components/tools/text/Text'
import themeColors from '@/assets/theme/colors'
import classes from './ArticleTemplate-CSS'

// Articles
import A0001 from './A0001'
import A0002 from './A0002'
import A0003 from './A0003'

type Props = {
  article: string
}

export default function ArticleTemplate(props: Props) {
  const { t } = useTranslation('articles')

  const router = useRouter()
  const currentLocale =
    router.query.locale || nextI18nextConfig.i18n.defaultLocale

  const { screenDimensions } = useContext(GlobalContext)

  const getArticle = () => {
    switch (props.article) {
      case 'A0001':
        return <A0001 />
      case 'A0002':
        return <A0002 />
      case 'A0003':
        return <A0003 />
    }
    return null
  }
  return (
    <Box
      sx={classes.mainDiv}
      style={{
        marginTop:
          screenDimensions.width > breakpoints.desktopToMobile ? '5rem' : '4rem'
      }}
    >
      <Box
        style={{
          minHeight: screenDimensions.height - 220,
          maxWidth: 800,
          padding: '1.25rem'
        }}
      >
        <Box>
          <Link
            href={translateHref(currentLocale, 'articles')}
            style={classes.typoLink}
          >
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <ArrowBackIcon style={{ transform: 'scale(0.7)' }} />
              <Text
                text={t('h1')}
                fontWeight={400}
                fontSize="0.875rem"
                color={themeColors.gray.mid}
              />
            </Box>
          </Link>
        </Box>

        {getArticle()}
      </Box>
    </Box>
  )
}
