import themeColors from '@/assets/theme/colors'

const classes = {
  section: {
    justifyContent: 'center',
    paddingTop: '3rem',
    paddingBottom: '3rem',
    paddingLeft: '1.25rem',
    paddingRight: '1.25rem'
  },
  cardBox: {
    display: 'grid'
  },
  card: {
    display: 'flex',
    borderRadius: '10px',
    margin: '10px',
    background: '#fff'
  },

  typeH4: {
    fontWeight: 800,
    margin: '15px'
  },
  learnMoreTypo: {
    color: themeColors.kroemtech.sensBlue,
    fontWeight: 600,
    fontSize: '1rem',
    '&:hover': {
      color: `${themeColors.kroemtech.sensBlue}cc`
    }
  },
  titleBox: {
    display: 'flex',
    alignItems: 'end',
    marginBottom: '20px'
  },
  learnHowBox: {
    marginLeft: '20px'
  },
  learnHowBox2: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px'
  }
}

export default classes
