// Style
import Link from 'next/link'
import classes from './EmailButton-CSS'

type Props = {
  mailto: string
  label: string
  color?: string
  noLinkEffects?: boolean
  useHoverEffect?: boolean
}

export default function EmailButton(props: Props) {
  return (
    <Link
      href="#"
      onClick={e => {
        window.location.href = props.mailto
        e.preventDefault()
      }}
      style={{
        textDecoration: props.noLinkEffects ? 'none' : undefined,
        color: props.color,
        ...(props.useHoverEffect ? classes.hoverEffects : undefined)
      }}
    >
      {props.label}
    </Link>
  )
}
