// React, Next
import { useTranslation } from 'next-i18next'
import { useContext, useEffect, useState } from 'react'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// Icons
import SecurityIcon from '@mui/icons-material/Security'
import VerifiedIcon from '@mui/icons-material/Verified'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import DataThresholdingIcon from '@mui/icons-material/DataThresholding'
import GroupsIcon from '@mui/icons-material/Groups'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'
import CardSection2 from '@/components/cards/cardSection2/CardSection2'

// Style
import themeColors from 'assets/theme/colors'
import breakpoints from 'assets/theme/breakpoints'
import classes from './WhyIotSection-CSS'

export default function WhyIotSection() {
  const { t } = useTranslation('applications')
  const { screenDimensions } = useContext(GlobalContext)

  const [mobileMode, setMobileMode] = useState(false)

  useEffect(() => {
    setMobileMode(screenDimensions.width <= breakpoints.desktopToMobile)
  }, [screenDimensions])

  const getFontH1 = () => {
    const w = screenDimensions.width

    if (w > breakpoints.desktopLarge) return '2.2rem'
    if (w > breakpoints.desktopSmall) return '2rem'
    if (w > breakpoints.mobileMid) return '1.875rem'
    if (w > breakpoints.mobileSmall) return '1.7rem'
    return '1.6rem'
  }

  const getPadding = () => {
    const w = screenDimensions.width

    if (w > breakpoints.desktopLarge) return '20vw'
    if (w > breakpoints.desktopMid) return '15vw'
    if (w > breakpoints.desktopSmall) return '10vw'
    if (w > breakpoints.desktopToMobile) return '5vw'
    if (w > breakpoints.mobileMid) return '5vw'
    if (w > breakpoints.mobileSmall) return '2vw'
    return '2vw'
  }

  const getGrid = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopToMobile) return 'span 6'
    return 'span 12'
  }

  const getCards = () => (
    <CardSection2
      titleColor={themeColors.bg.start}
      background="#ffffff00"
      alignedTexts={[
        {
          boldText: t('whyIot.subtitle1'),
          text: t('whyIot.body1'),
          icon: <SecurityIcon sx={{ color: themeColors.bg.start }} />
        },
        {
          boldText: t('whyIot.subtitle2'),
          text: t('whyIot.body2'),
          icon: <VerifiedIcon sx={{ color: themeColors.bg.start }} />
        },
        {
          boldText: t('whyIot.subtitle3'),
          text: t('whyIot.body3'),
          icon: <QueryStatsIcon sx={{ color: themeColors.bg.start }} />
        },
        {
          boldText: t('whyIot.subtitle4'),
          text: t('whyIot.body4'),
          icon: <DataThresholdingIcon sx={{ color: themeColors.bg.start }} />
        },
        {
          boldText: t('whyIot.subtitle5'),
          text: t('whyIot.body5'),
          icon: <GroupsIcon sx={{ color: themeColors.bg.start }} />
        },
        {
          boldText: t('whyIot.subtitle6'),
          text: t('whyIot.body6'),
          icon: <SettingsSuggestIcon sx={{ color: themeColors.bg.start }} />
        }
      ]}
      textColor={themeColors.gray.mid}
    />
  )

  // *** SECTION ***
  const getSection = () => (
    <Box
      gridColumn={getGrid()}
      sx={classes.subsection}
      style={{
        width: screenDimensions.width,
        paddingLeft: getPadding(),
        paddingRight: getPadding()
      }}
    >
      <Box style={classes.subsectionDiv}>
        <Typography
          variant="h1"
          fontSize={getFontH1()}
          style={{
            marginBottom: '1.875rem',
            textAlign: !mobileMode ? 'left' : 'center',
            color: themeColors.bg.start,
            fontWeight: 600
          }}
        >
          {t('whyIot.title')}
        </Typography>

        {getCards()}
      </Box>
    </Box>
  )

  // *** MAIN RENDER ***
  return (
    <Box
      component="section"
      style={classes.section}
      gridTemplateColumns="repeat(12, 1fr)"
    >
      {getSection()}
    </Box>
  )
}
