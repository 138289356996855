// React, Next
import { useTranslation } from 'next-i18next'
import { useContext, useEffect, useState } from 'react'
import { StaticImport } from 'next/dist/shared/lib/get-img-props'
import Image from 'next/image'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'

// Img
import Manufacturing from 'public/img/index/manufacturing_industry_w600.webp'
import Energy from 'public/img/index/energy_industry_w575.webp'
import Logistics from 'public/img/index/logistics_industry_w550.webp'
import Chemical from 'public/img/index/chemical_industry_w700.webp'
import Agriculture from 'public/img/index/farming_industry_w600.webp'
import Food from 'public/img/index/food_industry_w550.webp'
import SmartCities from 'public/img/index/smart_city_w580.webp'
import Building from 'public/img/index/building_automation_w800.webp'

// Types
import { SectionApplicationKey } from '@/types/global_types'

// Style
import themeColors from 'assets/theme/colors'
import breakpoints from 'assets/theme/breakpoints'
import classes from './IndustriesSection-CSS'

type Props = {
  scrollToSection: (section: SectionApplicationKey) => void
}

export default function IndustriesSection(props: Props) {
  const { t } = useTranslation('applications')
  const { screenDimensions } = useContext(GlobalContext)

  const getFontH2 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '1.2rem'
    if (w > breakpoints.desktopSmall) return '1.125rem'
    if (w > breakpoints.desktopToMobile) return '1.125rem'
    return '1rem'
  }
  const getFontH1 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '2rem'
    if (w > breakpoints.desktopSmall) return '1.75rem'
    if (w > breakpoints.desktopToMobile) return '1.625rem'
    if (w > breakpoints.mobileSmall) return '1.5rem'
    return '1.25rem'
  }

  const getFontH3 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return '1rem'
    if (w > breakpoints.desktopToMobile) return '0.8rem'
    if (w > breakpoints.mobileLarge) return '0.8rem'
    return '0.8rem'
  }

  const getPadding = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return `${screenDimensions.width / 20}px`
    if (w > breakpoints.desktopToMobile)
      return `${screenDimensions.width / 30}px`
    return '0px'
  }

  const getGrid2 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopToMobile) return 'span 6'
    if (w > breakpoints.mobileMid) return 'span 6'

    return 'span 12'
  }

  const getSectionMarginTop = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '8rem'
    if (w > breakpoints.desktopMid) return '7rem'
    if (w > breakpoints.desktopSmall) return '7rem'
    if (w > breakpoints.desktopToMobile) return '7rem'
    return '4rem'
  }

  const getImgSize = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return { width: 300, height: 200 }
    if (w > breakpoints.desktopSmall) return { width: 250, height: 150 }
    if (w > breakpoints.mobileLarge) return { width: 300, height: 200 }
    if (w > breakpoints.mobileMid) return { width: 250, height: 150 }
    return { width: '100%', height: 200 }
  }

  // *** Section ***
  const getCard = (p: {
    title: string
    textGroup: { SectionKey: SectionApplicationKey; text: string }[]
    textColor: string
    img: string | StaticImport
    alt: string
  }) => (
    <Box
      gridColumn={getGrid2()}
      sx={classes.cardBox}
      style={{
        flexDirection:
          screenDimensions.width < breakpoints.desktopSmall ? 'column' : 'row'
      }}
    >
      <Box>
        <Typography
          variant="h4"
          fontSize={getFontH2()}
          style={classes.typeH4}
          sx={{
            color: themeColors.gray.mid
          }}
        >
          {p.title}
        </Typography>
        <Image
          alt={p.alt}
          src={p.img}
          style={{
            width: getImgSize().width,
            height: getImgSize().height,
            objectFit: 'cover',
            borderRadius: '10px'
          }}
          priority={false}
        />
      </Box>
      <Box
        sx={classes.cardText}
        style={{
          marginTop:
            screenDimensions.width < breakpoints.desktopSmall
              ? '0.5rem'
              : '30px'
        }}
      >
        {p.textGroup.map((item, index) => (
          <Typography
            key={index}
            variant="subtitle1"
            fontSize={getFontH3()}
            style={{
              color: p.textColor,
              textAlign: 'left',
              lineHeight: 1.5
            }}
            sx={classes.Text}
            onClick={() => props.scrollToSection(item.SectionKey)}
          >
            &#x2022; {item.text}
          </Typography>
        ))}
      </Box>
    </Box>
  )

  const getSection = () => (
    <>
      <Box
        style={{
          zIndex: 2
        }}
      >
        <Typography
          variant="h3"
          fontSize={getFontH1()}
          style={{
            paddingLeft: getPadding(),
            color: themeColors.gray.mid,
            textAlign:
              screenDimensions.width < breakpoints.mobileSmall
                ? 'center'
                : 'left',
            fontWeight: 'bold',
            marginBottom: '2.5rem'
          }}
        >
          {t('applications.industries.title')}
        </Typography>
      </Box>
      <Box
        gridTemplateColumns="repeat(12, 1fr)"
        sx={classes.gridBox}
        style={{
          paddingLeft: getPadding(),
          paddingRight: getPadding()
        }}
      >
        {getCard({
          title: t('applications.industries.branches.manufacturing'),
          alt: 'Manufacturing',
          img: Manufacturing,
          textGroup: [
            {
              SectionKey: 'motorHealth',
              text: t('cards.motorHealth.title')
            },
            {
              SectionKey: 'airQuality',
              text: t('cards.airQuality.title')
            },
            {
              SectionKey: 'workerSafety',
              text: t('cards.workerSafety.title')
            },
            {
              SectionKey: 'energyConsumption',
              text: t('cards.energyConsumption.title')
            },
            {
              SectionKey: 'qualityControl',
              text: t('cards.qualityControl.title')
            }
          ],
          textColor: themeColors.gray.mid
        })}
        {getCard({
          title: t('applications.industries.branches.food'),
          alt: 'Food',
          img: Food,
          textGroup: [
            {
              SectionKey: 'airQuality',
              text: t('cards.airQuality.title')
            },
            {
              SectionKey: 'waterQuality',
              text: t('cards.waterQuality.title')
            },
            {
              SectionKey: 'spoiledFood',
              text: t('cards.spoiledFood.title')
            },
            {
              SectionKey: 'ripeningControl',
              text: t('cards.ripeningControl.title')
            },
            {
              SectionKey: 'qualityControl',
              text: t('cards.qualityControl.title')
            }
          ],
          textColor: themeColors.gray.mid
        })}
        {getCard({
          title: t('applications.industries.branches.chemical'),
          alt: 'Chemical',
          img: Chemical,
          textGroup: [
            {
              SectionKey: 'leakDetection',
              text: t('cards.leakDetection.title')
            },
            {
              SectionKey: 'airQuality',
              text: t('cards.airQuality.title')
            },
            {
              SectionKey: 'workerSafety',
              text: t('cards.workerSafety.title')
            },
            {
              SectionKey: 'energyConsumption',
              text: t('cards.energyConsumption.title')
            },
            {
              SectionKey: 'motorHealth',
              text: t('cards.motorHealth.title')
            },
            {
              SectionKey: 'undergroundWater',
              text: t('cards.undergroundWater.title')
            }
          ],
          textColor: themeColors.gray.mid
        })}
        {getCard({
          title: t('applications.industries.branches.farming'),
          alt: 'Agriculture',
          img: Agriculture,
          textGroup: [
            {
              SectionKey: 'waterQuality',
              text: t('cards.waterQuality.title')
            },
            {
              SectionKey: 'soil',
              text: t('cards.soil.title')
            },
            {
              SectionKey: 'solarRadiation',
              text: t('cards.solarRadiation.title')
            }
          ],
          textColor: themeColors.gray.mid
        })}
        {getCard({
          title: t('applications.industries.branches.transport'),
          alt: 'Logistics',
          img: Logistics,
          textGroup: [
            {
              SectionKey: 'goodsTracking',
              text: t('cards.goodsTracking.title')
            },
            {
              SectionKey: 'spoiledFood',
              text: t('cards.spoiledFood.title')
            },
            {
              SectionKey: 'ripeningControl',
              text: t('cards.ripeningControl.title')
            }
          ],
          textColor: themeColors.gray.mid
        })}
        {getCard({
          title: t('applications.industries.branches.cities'),
          alt: 'SmartCities',
          img: SmartCities,
          textGroup: [
            {
              SectionKey: 'airQuality',
              text: t('cards.airQuality.title')
            },
            {
              SectionKey: 'waterQuality',
              text: t('cards.waterQuality.title')
            },
            {
              SectionKey: 'naturalCondition',
              text: t('cards.naturalCondition.title')
            },
            {
              SectionKey: 'undergroundWater',
              text: t('cards.undergroundWater.title')
            }
          ],
          textColor: themeColors.gray.mid
        })}
        {getCard({
          title: t('applications.industries.branches.energy'),
          alt: 'Energy',
          img: Energy,
          textGroup: [
            {
              SectionKey: 'airQuality',
              text: t('cards.airQuality.title')
            },
            {
              SectionKey: 'energyConsumption',
              text: t('cards.energyConsumption.title')
            },
            {
              SectionKey: 'workerSafety',
              text: t('cards.workerSafety.title')
            }
          ],
          textColor: themeColors.gray.mid
        })}
        {getCard({
          title: t('applications.industries.branches.building'),
          alt: 'Building',
          img: Building,
          textGroup: [
            {
              SectionKey: 'airQuality',
              text: t('cards.airQuality.title')
            },
            {
              SectionKey: 'waterQuality',
              text: t('cards.waterQuality.title')
            },
            {
              SectionKey: 'energyConsumption',
              text: t('cards.energyConsumption.title')
            }
          ],
          textColor: themeColors.gray.mid
        })}
      </Box>
    </>
  )

  // *** MAIN RENDER ***
  return (
    <Box
      component="section"
      style={{
        ...classes.section,
        marginTop: getSectionMarginTop(),
        marginBottom: getSectionMarginTop()
      }}
    >
      {getSection()}
    </Box>
  )
}
