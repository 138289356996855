import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ReactElement,
  useContext,
  forwardRef,
  useImperativeHandle
} from 'react'
import Box from '@mui/material/Box'

// Icons
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'

import { GlobalContext } from 'context/GlobalContextProvider'
import { getMax } from '@/assets/functions/math'
import breakpoints from '@/assets/theme/breakpoints'
import classes from './PhotoCarrousel-CSS'

type ButtonProps = {
  enabled: boolean
  buttonMargin?: number | string
  arrowSize?: number
  top: number | string
  onClick: () => void
}

function PrevButton(p: ButtonProps) {
  if (p.enabled) {
    return (
      <Box
        onClick={p.onClick}
        sx={{
          left: '15px',
          top: p.top,
          marginLeft: p.buttonMargin === undefined ? '15px' : p.buttonMargin,
          background: '#efe'
        }}
        style={classes.emblaButton}
      >
        <ArrowBackIosNewIcon
          sx={{
            transform:
              p.arrowSize === undefined ? 'scale(0.8)' : `scale(${p.arrowSize})`
          }}
          style={classes.arrowButtonColor}
        />
      </Box>
    )
  }
  return null
}

function NextButton(p: ButtonProps) {
  if (p.enabled) {
    return (
      <Box
        onClick={p.onClick}
        style={classes.emblaButton}
        sx={{
          right: '15px',
          top: p.top,
          marginRight: p.buttonMargin === undefined ? '15px' : p.buttonMargin

          // position:    'absolute',
        }}
      >
        <ArrowForwardIosIcon
          style={classes.arrowButtonColor}
          sx={{
            transform:
              p.arrowSize === undefined
                ? 'scale(0.75)'
                : `scale(${p.arrowSize})`
          }}
        />
      </Box>
    )
  }
  return null
}

type Props = {
  marginLeft?: number | string
  buttonLeftMargin?: number | string
  buttonRightMargin?: number | string
  arrowSize?: number
  minSlideWidth?: number | string
  slides: ReactElement[]
  setCurrentSlideNo?: (slideIndex: number) => void
}
export default forwardRef((props: Props, ref) => {
  const autoplay = useRef(Autoplay({ delay: 11000, stopOnInteraction: false }))

  const Ref: any = useRef()

  useImperativeHandle(ref, () => ({
    getSlidesInView
  }))

  const getSlidesInView = () => shownSlides()

  const { screenDimensions } = useContext(GlobalContext)

  const [viewportRef, embla] = useEmblaCarousel(
    {
      align: 'start',
      skipSnaps: false,
      loop: false
    },
    [autoplay.current]
  )
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  const scrollNext = useCallback(() => {
    if (!embla) return
    embla.scrollNext()
    autoplay.current.reset()
  }, [embla])

  const scrollPrev = useCallback(() => {
    if (!embla) return
    embla.scrollPrev()
    autoplay.current.reset()
  }, [embla])

  const shownSlides = useCallback(() => {
    if (!embla) return
    return getMax(embla.slidesInView())
  }, [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setPrevBtnEnabled(embla.canScrollPrev())
    if (embla.selectedScrollSnap() === 0 && props.slides.length > 1)
      setNextBtnEnabled(true)
    else setNextBtnEnabled(embla.canScrollNext())
    if (props.setCurrentSlideNo)
      props.setCurrentSlideNo(embla.selectedScrollSnap())
  }, [embla])

  useEffect(() => {
    if (!embla) return
    embla.on('select', onSelect)
    onSelect()
  }, [embla, onSelect])

  return (
    <Box
      style={classes.embla}
      ref={Ref}
      sx={{
        maxWidth: screenDimensions.width,
        width: screenDimensions.width,
        marginLeft: props.marginLeft
      }}
    >
      {PrevButton({
        onClick: () => scrollPrev(),
        enabled: prevBtnEnabled,
        buttonMargin: props.buttonLeftMargin,
        arrowSize: props.arrowSize,
        top: screenDimensions.width > breakpoints.mobileMid ? '50%' : '75%'
      })}
      {NextButton({
        onClick: () => scrollNext(),
        enabled: nextBtnEnabled,
        buttonMargin: props.buttonRightMargin,
        arrowSize: props.arrowSize,
        top: screenDimensions.width > breakpoints.mobileMid ? '50%' : '75%'
      })}

      <div style={classes.emblaViewport} ref={viewportRef}>
        <div style={classes.emblaContainer}>
          {props.slides.map((slide, i) => (
            <div
              key={i}
              style={{ minWidth: props.minSlideWidth, ...classes.emblaSlide }}
            >
              <div style={classes.emblaSlideInner}>{slide}</div>
            </div>
          ))}
        </div>
      </div>
    </Box>
  )
})
