// React, Next
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'

// MUI
import Box from '@mui/material/Box'
import { motion } from 'framer-motion'

// Custom
import KroemtechSingleRectangleSvg from 'components/tools/icons/KroemtechSingleRectangleSvg'

// Sections
import GetStartedSection from '@/components/getStarted/GetStartedSection'
import HeroSection2 from './sections/HeroSection2'
import ServicesSection from './sections/ServicesSection'
import SolutionsSection from './sections/SolutionsSection'
import RealAplicationsSection from './sections/RealAplicationsSection'
import SuccessSection from './sections/SuccessSection'
import ConseptSection from './sections/ConseptSection'
import BenefitsSection from './sections/BenefitsSection'
import OurTechSection from './sections/OurTechSection'

// Style
import classes from './IndexView-CSS'

export default function IndexView() {
  const { t } = useTranslation('index')

  const servicesRef: any = useRef()
  const ourTechRef: any = useRef()
  const techRef: any = useRef()
  const solutionsRef: any = useRef()
  const realAplicationsRef: any = useRef()
  const successRef: any = useRef()
  const conseptRef: any = useRef()
  const benefitsRef: any = useRef()

  const [servicesVisible, setServicesVisible] = useState(false)
  const [ourTechVisible, setOurTechVisible] = useState(false)
  const [techVisible, setTechVisible] = useState(false)
  const [solutionsVisible, setSolutionsVisible] = useState(false)
  const [realAplicationsVisible, setRealAplicationsVisible] = useState(false)
  const [successVisible, setSuccessVisible] = useState(false)
  const [conseptVisible, setConseptVisible] = useState(false)
  const [benefitsVisible, setBenefitsVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setServicesVisible(entry.isIntersecting))
    })
    observer.observe(servicesRef.current)
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setOurTechVisible(entry.isIntersecting))
    })
    observer.observe(ourTechRef.current)
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setTechVisible(entry.isIntersecting))
    })
    observer.observe(techRef.current)
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setSolutionsVisible(entry.isIntersecting))
    })
    observer.observe(solutionsRef.current)
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setRealAplicationsVisible(entry.isIntersecting))
    })
    observer.observe(realAplicationsRef.current)
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setSuccessVisible(entry.isIntersecting))
    })
    observer.observe(successRef.current)
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setConseptVisible(entry.isIntersecting))
    })
    observer.observe(conseptRef.current)
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setBenefitsVisible(entry.isIntersecting))
    })
    observer.observe(benefitsRef.current)
  }, [])

  // *** MAIN RENDER ***
  const getSection = (p: {
    section: ReactElement
    reference: any
    visible: boolean
  }) => (
    <motion.div
      ref={p.reference}
      initial={{ opacity: 0, y: 20 }}
      animate={p.visible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ delay: 0.07, duration: 0.75 }}
    >
      {p.section}
    </motion.div>
  )

  return (
    <Box sx={classes.mainBox} component="div">
      <HeroSection2 />

      <KroemtechSingleRectangleSvg
        variant={1}
        color={2}
        opacity={0.1}
        marginLeft={-100}
        marginTop={2150}
      />

      {getSection({
        section: <OurTechSection />,
        reference: ourTechRef,
        visible: ourTechVisible
      })}

      {getSection({
        section: <ServicesSection />,
        reference: servicesRef,
        visible: servicesVisible
      })}

      {getSection({
        section: <SuccessSection />,
        reference: successRef,
        visible: successVisible
      })}

      {getSection({
        section: <ConseptSection />,
        reference: conseptRef,
        visible: conseptVisible
      })}

      {getSection({
        section: <BenefitsSection />,
        reference: benefitsRef,
        visible: benefitsVisible
      })}

      {getSection({
        section: <SolutionsSection />,
        reference: solutionsRef,
        visible: solutionsVisible
      })}

      {getSection({
        section: <RealAplicationsSection />,
        reference: realAplicationsRef,
        visible: realAplicationsVisible
      })}

      {getSection({
        section: (
          <GetStartedSection
            title={t('started.title')}
            subTitle={t('started.subTitle')}
            title2={t('started.title2')}
            subTitle2={t('started.subTitle2')}
            button={t('started.buttonTitle')}
          />
        ),
        reference: techRef,
        visible: techVisible
      })}
    </Box>
  )
}
