// React, Next
import { useContext, useEffect, useState } from 'react'
import Image, { StaticImageData } from 'next/image'

// MUI
import Box from '@mui/material/Box'
import DoneRoundedIcon from '@mui/icons-material/DoneRounded'

// Custom
import Typography from '@mui/material/Typography'

// Config
import { GlobalContext } from 'context/GlobalContextProvider'

// Style
import breakpoints from 'assets/theme/breakpoints'
import { getFontH5 } from '@/assets/theme/fonts'
import classes from './DescriptionSection-CSS'

type AlignedTextProps = {
  text: string
}

type PropTypes = {
  image: StaticImageData
  description: string
  body: string
  alignedTexts: AlignedTextProps[]
  textColor: string
}

export default function DescriptionSection(props: PropTypes) {
  const [mobileMode, setMobileMode] = useState(false)
  const { screenDimensions } = useContext(GlobalContext)

  useEffect(() => {
    setMobileMode(screenDimensions.width <= breakpoints.desktopToMobile)
  }, [screenDimensions])

  const getGrid = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopToMobile) return 'span 6'
    return 'span 12'
  }

  const getSectionMarginTop = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '6rem'
    if (w > breakpoints.desktopMid) return '4rem'
    if (w > breakpoints.desktopSmall) return '4rem'
    if (w > breakpoints.desktopToMobile) return '5rem'
    if (w > breakpoints.mobileMid) return '5rem'
    if (w > breakpoints.mobileSmall) return '5rem'
    return '5rem'
  }

  const getPaddingLeft = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '16rem'
    if (w > breakpoints.desktopMid) return '8rem'
    if (w > breakpoints.desktopToMobile) return '3rem'
    return '1rem'
  }

  const getImageWidth = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return 300
    if (w > breakpoints.desktopToMobile) return 250
    return 200
  }

  // *** SECTION ***
  const getSection = () => (
    <>
      <Box>
        <Image
          alt=""
          src={props.image}
          style={{
            objectFit: 'cover',
            objectPosition: '50% 50%',
            marginBottom: '1rem'
          }}
          priority={false}
          width={getImageWidth()}
        />
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          gap: mobileMode ? 3 : 6
        }}
      >
        <Box
          gridColumn={getGrid()}
          style={{
            justifyContent: !mobileMode ? 'left' : 'center'
          }}
        >
          <Typography fontSize={getFontH5()} sx={classes.typoB1}>
            {props.description}
          </Typography>
        </Box>
        <Box
          gridColumn={getGrid()}
          style={{
            justifyContent: !mobileMode ? 'left' : 'center',
            paddingLeft: mobileMode ? '0rem' : '5rem'
          }}
        >
          {props.alignedTexts.map((item, index) => (
            <Box style={{ display: 'flex' }} key={index}>
              <DoneRoundedIcon sx={{ color: props.textColor }} />
              <Typography
                fontSize={getFontH5()}
                style={{
                  color: props.textColor,
                  textAlign: 'left',
                  marginLeft: '15px',
                  fontWeight: '500'
                }}
              >
                {item.text}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          gap: mobileMode ? 3 : 6,
          marginTop: '2rem'
        }}
      >
        <Typography
          fontSize={getFontH5()}
          sx={classes.typoB1}
          gridColumn="span 12"
        >
          {props.body}
        </Typography>
      </Box>
    </>
  )

  // *** MAIN RENDER ***
  return (
    <Box
      component="section"
      sx={{
        paddingBottom: getSectionMarginTop(),
        paddingTop: getSectionMarginTop(),
        paddingLeft: getPaddingLeft(),
        paddingRight: getPaddingLeft(),
        boxShadow: 'rgb(0 0 0 / 2%) 0px 15px 20px 0px;'
      }}
    >
      {getSection()}
    </Box>
  )
}
