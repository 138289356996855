// React, Next
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import VerifiedSharpIcon from '@mui/icons-material/VerifiedSharp'

// Context
import Link from 'next/link'
import { GlobalContext } from 'context/GlobalContextProvider'

// Custom
import AlignedText from '@/components/alignedText/AlignedText'

// Img
import Cloud from 'public/img/index/scygate.webp'

// Config
import i18nextConfig from 'next-i18next.config'
import { translateHref } from '@/assets/functions/links'

// Style
import themeColors from 'assets/theme/colors'
import breakpoints from 'assets/theme/breakpoints'
import classes from './OurTechSection-CSS'

export default function SuccessSection() {
  const { t } = useTranslation('index')
  const { screenDimensions } = useContext(GlobalContext)

  const [mobileMode, setMobileMode] = useState(false)
  const router = useRouter()
  const currentLocale = router.query.locale || i18nextConfig.i18n.defaultLocale

  useEffect(() => {
    setMobileMode(screenDimensions.width <= breakpoints.desktopToMobile)
  }, [screenDimensions])

  const getFontH1 = () => {
    const w = screenDimensions.width

    if (w > breakpoints.desktopLarge) return '2.6rem'
    if (w > breakpoints.desktopSmall) return '2.3rem'
    if (w > breakpoints.mobileMid) return '2rem'
    if (w > breakpoints.mobileSmall) return '1.875rem'
    return '1.625rem'
  }

  const getFontH2 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '1.1rem'
    if (w > breakpoints.desktopMid) return '1rem'
    if (w > breakpoints.desktopSmall) return '1rem'
    if (w > breakpoints.desktopToMobile) return '1rem'
    if (w > breakpoints.mobileLarge) return '1rem'
    if (w > breakpoints.mobileMid) return '1rem'
    if (w > breakpoints.mobileSmall) return '0.9rem'
    if (w > breakpoints.mobileSmaller) return '0.8rem'
    return '0.8rem'
  }

  const getPaddingLeft = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return `${screenDimensions.width / 15}px`
    if (w > breakpoints.desktopSmall) return `${screenDimensions.width / 20}px`
    if (w > breakpoints.desktopToMobile)
      return `${screenDimensions.width / 25}px`
    return '0px'
  }

  const getGrid = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopToMobile) return 'span 6'
    return 'span 12'
  }

  const getImgSize = () => {
    if (screenDimensions.width > breakpoints.desktopLarge) {
      return {
        width: 600,
        height: 600
      }
    }
    if (!mobileMode) {
      return {
        width: 450,
        height: 450
      }
    }
    return {
      width: screenDimensions.width / 2,
      height: screenDimensions.width / 2
    }
  }

  const getBullet = (text: string) => (
    <AlignedText
      icon={
        <VerifiedSharpIcon
          style={classes.icon}
          sx={{
            color: themeColors.bg.start
          }}
        />
      }
      boldText={text}
      textColor={themeColors.gray.mid}
      itemMarginBottom="0"
    />
  )

  // *** SECTION ***
  const getSection = () => (
    <>
      {mobileMode ? null : (
        <Box
          gridColumn={getGrid()}
          sx={classes.subsection}
          style={{
            paddingLeft: getPaddingLeft()
          }}
        >
          <Image
            alt="Kroemtech"
            src={Cloud}
            style={{
              borderRadius: mobileMode ? 0 : '5px',
              width: getImgSize().width,
              height: getImgSize().height,
              opacity: 0.9,
              boxShadow: mobileMode
                ? 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'
                : 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px',
              objectFit: 'cover'
            }}
            priority={false}
          />
        </Box>
      )}
      <Box
        gridColumn={getGrid()}
        sx={classes.subsection}
        style={{
          paddingRight: !mobileMode ? getPaddingLeft() : '0px',
          marginBottom: mobileMode ? '3.125rem' : 0,
          justifyContent: !mobileMode ? 'left' : 'center'
        }}
      >
        <Box style={classes.subsectionDiv}>
          <Typography
            variant="h1"
            fontSize={getFontH1()}
            style={{
              marginBottom: '1.875rem',
              textAlign: !mobileMode ? 'left' : 'center',
              color: themeColors.bg.start,
              fontWeight: 600
            }}
          >
            {t('ourTechnology.title')}
          </Typography>

          {mobileMode ? (
            <Box
              gridColumn={getGrid()}
              sx={{
                ...classes.subsection,
                ...classes.mobileImg,
                paddingLeft: getPaddingLeft()
              }}
            >
              <Image
                alt="Kroemtech"
                src={Cloud}
                style={{
                  borderRadius: mobileMode ? 0 : '5px',
                  width: getImgSize().width,
                  height: getImgSize().height,
                  opacity: 0.9,
                  boxShadow: mobileMode
                    ? 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'
                    : 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px',
                  objectFit: 'cover'
                }}
                priority={false}
              />
            </Box>
          ) : null}
          <Typography
            fontSize={getFontH2()}
            style={{
              color: themeColors.gray.mid,
              textAlign: !mobileMode ? 'left' : 'left',
              lineHeight: 1.5,
              marginBottom: '1.875rem'
            }}
          >
            {t('ourTechnology.body1')}
          </Typography>
          {getBullet(t('ourTechnology.body2'))}
          {getBullet(t('ourTechnology.body3'))}
          <Link href={translateHref(currentLocale, 'technology')}>
            <Box sx={classes.learnMoreBox}>
              <Typography variant="body1" sx={classes.learnMoreTypo}>
                {t('learnMore')} &#x279C;
              </Typography>
            </Box>
          </Link>
        </Box>
      </Box>
    </>
  )

  // *** MAIN RENDER ***
  return (
    <Box
      component="section"
      style={classes.section}
      gridTemplateColumns="repeat(12, 1fr)"
    >
      {getSection()}
    </Box>
  )
}
