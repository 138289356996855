// React, Next
import { useTranslation } from 'next-i18next'
import { useContext } from 'react'
import Image from 'next/image'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// Custom
import Link from 'next/link'
import { GlobalContext } from 'context/GlobalContextProvider'

// Img
import Building from 'public/img/contact/kroemtech_location.jpg'

// Style
import breakpoints from 'assets/theme/breakpoints'
import themeColors from 'assets/theme/colors'

// import { ContactFormInterface } from 'api/contact/interfaces'
// import { SendContactForm } from 'api/contact/contact'
import classes from './ContactView-CSS'
import ContactForm from './sections/ContactForm'

export default function ContactView() {
  const { t } = useTranslation('contact')
  const { screenDimensions } = useContext(GlobalContext)

  /*
  const onSubmit = async () => {
    const formContent:ContactFormInterface = {
      name_prefix: 'Mr.',
      first_name:  'fName',
      last_name:   'lName',
      company:     'myConpany',
      email:       'example@example.com',
      phone:       '+99999999999',
      message:     'This is a test',
    }

    const res = await SendContactForm(formContent)

    console.log('response:')
    console.log(res)

  }
*/

  const getGrid1 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopToMobile) return 'span 8'
    return 'span 12'
  }

  const getGrid2 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopToMobile) return 'span 4'
    return 'span 12'
  }

  const getFontH3 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '2rem'
    if (w > breakpoints.desktopSmall) return '1.75rem'
    if (w > breakpoints.desktopToMobile) return '1.625rem'
    if (w > breakpoints.mobileSmall) return '1.5rem'
    return '1.25rem'
  }

  const getFontB1 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopToMobile) return '1rem'
    return '0.875rem'
  }

  const getImgSize = () => {
    // w980 h656
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid)
      return { width: 300, height: (656 * 300) / 980 }
    if (w > breakpoints.mobileSmaller)
      return { width: 250, height: (656 * 250) / 980 }
    return { width: 200, height: (656 * 200) / 980 }
  }

  const getPaddingLeft = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return `${screenDimensions.width / 14}px`
    if (w > breakpoints.desktopSmall) return `${screenDimensions.width / 16}px`
    if (w > breakpoints.desktopToMobile)
      return `${screenDimensions.width / 20}px`
    return '0px'
  }

  const getPadding = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return `${screenDimensions.width / 8}px`
    if (w > breakpoints.desktopSmall) return `${screenDimensions.width / 12}px`
    if (w > breakpoints.desktopToMobile)
      return `${screenDimensions.width / 20}px`
    if (w > breakpoints.mobileSmall) return `${screenDimensions.width / 12}px`
    return `${screenDimensions.width / 16}px`
  }

  return (
    <div>
      <Box>
        <Box
          component="section"
          gridTemplateColumns="repeat(12, 1fr)"
          style={classes.section}
        >
          <Box
            gridColumn={getGrid1()}
            style={{
              paddingLeft: getPadding(),
              paddingRight: getPadding(),
              paddingTop:
                screenDimensions.width > breakpoints.desktopToMobile
                  ? '1.25rem'
                  : '0.625rem',
              paddingBottom: '3.125rem',
              marginBottom:
                screenDimensions.width > breakpoints.desktopToMobile
                  ? '0.625rem'
                  : '3.125rem'
            }}
          >
            <Typography
              style={{
                fontSize: getFontH3(),
                marginBottom: '2.5rem'
              }}
            >
              <b>{t('formTitle')}</b>
            </Typography>
            <ContactForm />
          </Box>
          <Box
            gridColumn={getGrid2()}
            style={{
              paddingLeft: getPaddingLeft(),
              background: themeColors.info.lightest,
              paddingTop:
                screenDimensions.width > breakpoints.desktopToMobile
                  ? '12vh'
                  : '1.875rem',
              display: 'flex',
              justifyContent:
                screenDimensions.width > breakpoints.desktopToMobile
                  ? undefined
                  : 'center',
              marginTop: '-2.5rem',
              paddingBottom:
                screenDimensions.width > breakpoints.desktopToMobile
                  ? undefined
                  : '3rem',
              color: '#000'
            }}
          >
            <div>
              <Image
                alt="Kroemtech"
                src={Building}
                style={{
                  width: getImgSize().width,
                  height: getImgSize().height,
                  marginTop:
                    screenDimensions.width > breakpoints.desktopToMobile
                      ? undefined
                      : '3rem',
                  opacity: 0.9,
                  boxShadow:
                    'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
                  borderRadius: '10%'

                  // objectPosition: '50% 10%',
                  // objectFit:    'fill',
                }}
                priority={false}
              />

              <Typography
                style={{
                  fontSize: getFontB1(),
                  marginTop: '3.125rem'
                }}
              >
                <b>Kroemtech GmbH</b>
                <br />
                Christoph Merian-Ring 11
                <br />
                CH-4153 Reinach
                <br />
                {t('ch')}
              </Typography>
              <Typography
                style={{
                  fontSize: getFontB1(),
                  marginTop: '1.875rem'
                }}
              >
                {t('phoneLetter')} +41 61 717 82 45
              </Typography>
              <Link href="mailto:info@kroemtech.com">
                <Typography
                  style={{
                    fontSize: getFontB1(),
                    marginTop: '1.875rem'
                  }}
                >
                  info@kroemtech.com
                </Typography>
              </Link>
              <Typography
                style={{
                  fontSize: getFontB1(),
                  marginTop: '1.875rem',
                  marginBottom:
                    screenDimensions.width > breakpoints.desktopToMobile
                      ? undefined
                      : '40px'
                }}
              >
                {t('moFr')}
                <br />
                08:00–12:00 {t('hours')}
                <br />
                13:00–17:00 {t('hours')}
              </Typography>
            </div>
          </Box>
        </Box>
      </Box>
    </div>
  )
}
