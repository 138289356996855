import React, { useContext, useEffect, useState, ReactNode } from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import breakpoints from 'assets/theme/breakpoints'
import { GlobalContext } from 'context/GlobalContextProvider'
import classes from './AlignedText-CSS'

type PropTypes = {
  icon?: ReactNode
  iconWidth?: string
  boldText?: string
  description?: string
  textColor: string
  titleAlignText?: string
  itemMarginBottom?: string
  subtitleMarginTop?: string
}

export default function AlignedText(props: PropTypes) {
  const { screenDimensions } = useContext(GlobalContext)
  const [mobileMode, setMobileMode] = useState(false)

  useEffect(() => {
    setMobileMode(screenDimensions.width <= breakpoints.desktopToMobile)
  }, [screenDimensions])

  const getFontH2 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '1.1rem'
    if (w > breakpoints.desktopMid) return '1rem'
    if (w > breakpoints.desktopSmall) return '1rem'
    if (w > breakpoints.desktopToMobile) return '1rem'
    if (w > breakpoints.mobileLarge) return '1rem'
    if (w > breakpoints.mobileMid) return '1rem'
    if (w > breakpoints.mobileSmall) return '0.9rem'
    if (w > breakpoints.mobileSmaller) return '0.8rem'
    return '0.8rem'
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: props.itemMarginBottom ?? '0.5rem'
      }}
    >
      <Box
        style={{
          ...classes.TitleContainer,
          alignItems: props.titleAlignText ?? 'center'
        }}
      >
        {props.icon && (
          <>
            {React.cloneElement(props.icon as React.ReactElement, {
              style: {
                width: props.iconWidth ?? '50px'
              }
            })}
          </>
        )}

        <Typography
          variant="subtitle1"
          fontSize={getFontH2()}
          style={{
            color: props.textColor,
            textAlign: !mobileMode ? 'left' : 'left',
            lineHeight: 1.5,
            marginLeft: props.icon ? '0px' : '50px'
          }}
        >
          <b>{props.boldText}</b>
        </Typography>
      </Box>
      <Typography
        variant="subtitle1"
        fontSize={getFontH2()}
        style={{
          color: props.textColor,
          textAlign: !mobileMode ? 'left' : 'left',
          lineHeight: 1.5,
          marginTop: props.subtitleMarginTop ?? '0.5rem',
          marginLeft: props.iconWidth ?? '50px'
        }}
      >
        {props.description}
      </Typography>
    </Box>
  )
}
