// React, Next
import { useTranslation } from 'next-i18next'
import { useContext } from 'react'

// MUI
import Box from '@mui/material/Box'
import VerifiedSharpIcon from '@mui/icons-material/VerifiedSharp'
import DashboardCustomizeRoundedIcon from '@mui/icons-material/DashboardCustomizeRounded'
import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded'
import JoinFullIcon from '@mui/icons-material/JoinFull'

// Img
import Interative from 'public/img/services/hands_wolding_tablet_w1350.webp'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'
import themeColors from 'assets/theme/colors'
import breakpoints from 'assets/theme/breakpoints'
import CardSection from '@/components/cards/cardSection/CardSection'
import CardSection2 from '@/components/cards/cardSection2/CardSection2'

// Style
import classes from './AnalyticsSection-CSS'

export default function AnalyticsSection() {
  const { t } = useTranslation('services')

  const { screenDimensions } = useContext(GlobalContext)

  const getSectionMarginTop = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '6rem'
    if (w > breakpoints.desktopMid) return '4rem'
    if (w > breakpoints.desktopSmall) return '4rem'
    if (w > breakpoints.desktopToMobile) return '5rem'
    if (w > breakpoints.mobileMid) return '5rem'
    if (w > breakpoints.mobileSmall) return '5rem'
    return '5rem'
  }

  const getSectionPadding = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '12rem'
    if (w > breakpoints.desktopMid) return '6rem'
    if (w > breakpoints.desktopToMobile) return '3rem'
    return '1rem'
  }

  // *** SECTION ***
  const getSection = () => (
    <CardSection
      title={t('cards.04.title')}
      titleColor={themeColors.bg.start}
      icon={<VerifiedSharpIcon sx={{ color: themeColors.bg.start }} />}
      text={[t('cards.04.subtitle.01'), t('cards.04.subtitle.02')]}
      textColor={themeColors.gray.mid}
      description={t('cards.04.description')}
      image={Interative}
      imageAlt="Kroemtech"
      module="2"
    />
  )
  const getSection2 = () => (
    <CardSection2
      title={t('cards.benefitsText')}
      titleColor={themeColors.bg.start}
      alignedTexts={[
        {
          boldText: t('cards.04.benefits.bold01'),
          text: t('cards.04.benefits.01'),
          icon: (
            <DashboardCustomizeRoundedIcon
              sx={{ color: themeColors.bg.start }}
            />
          )
        },
        {
          boldText: t('cards.04.benefits.bold02'),
          text: t('cards.04.benefits.02'),
          icon: <ViewModuleRoundedIcon sx={{ color: themeColors.bg.start }} />
        },
        {
          boldText: t('cards.04.benefits.bold03'),
          text: t('cards.04.benefits.03'),
          icon: <JoinFullIcon sx={{ color: themeColors.bg.start }} />
        }
      ]}
      textColor={themeColors.gray.mid}
    />
  )

  // *** MAIN RENDER ***
  return (
    <Box
      component="section"
      sx={{
        ...classes.section,
        gap: screenDimensions.width <= breakpoints.desktopToMobile ? 0 : '3rem',
        paddingTop: getSectionMarginTop(),
        paddingBottom: getSectionMarginTop(),
        paddingLeft: getSectionPadding(),
        paddingRight: getSectionPadding()
      }}
      gridTemplateColumns="repeat(12, 1fr )"
    >
      {getSection()}
      {getSection2()}
    </Box>
  )
}
