import themeColors from '@/assets/theme/colors'

const classes = {
  section: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    justifyContent: 'center',
    background: themeColors.kroemtech.background,
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '20%',
      height: '4px',
      backgroundColor: themeColors.gray.mid
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: '20%',
      height: '4px',
      backgroundColor: themeColors.gray.mid
    }
  },
  section1: {
    gridColumn: 'span 6',
    display: 'grid',
    gap: '16px'
  },
  section2: {
    gridColumn: 'span 12'
  },
  card: {
    width: '100%'
  }
}

export default classes
