import themeColors from 'assets/theme/colors'

const classes = {
  mainBox: {
    display: 'block',
    background: themeColors.bg.start
  },
  upperBox: {
    display: 'grid'
  },
  lowerBox: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: '1px solid #ddd',
    alignItems: 'center',
    height: '60px',
    marginLeft: '10%', // Define a margem esquerda
    marginRight: '10%'
  },
  linkBox: {
    alignItems: 'center',
    justifyContent: 'center',
    color: themeColors.gray.dark,
    '&:hover': {
      opacity: 0.7
    },
    '&:active': {
      opacity: 0.85
    }
  },
  typoLink: {
    textDecoration: 'none'
  },
  col: {
    padding: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}

export default classes
