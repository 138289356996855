// React, Next
import { useTranslation } from 'next-i18next'
import { ReactNode, useContext } from 'react'
import { StaticImageData } from 'next/image'

// MUI
import Box from '@mui/material/Box'
import VerifiedSharpIcon from '@mui/icons-material/VerifiedSharp'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'
import themeColors from 'assets/theme/colors'
import breakpoints from 'assets/theme/breakpoints'
import CardSectionReverse from '@/components/cards/cardSectionReverse/CardSectionReverse'
import CardSection2 from '@/components/cards/cardSection2/CardSection2'

interface PropTypes {
  cardNumber: number
  cardsNumberTitle: any
  cardsNumberCondition: any
  cardsNumberDescription: any
  image: StaticImageData | string
  background: string
  alignedTexts: { boldText: any; text: any; icon: ReactNode }[]
  scrollTop: () => void
}

export default function GlobalSection(props: PropTypes) {
  const { t } = useTranslation('applications')

  const { screenDimensions } = useContext(GlobalContext)

  const getSectionMarginTop = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '6rem'
    if (w > breakpoints.desktopMid) return '4rem'
    if (w > breakpoints.desktopSmall) return '4rem'
    if (w > breakpoints.desktopToMobile) return '5rem'
    if (w > breakpoints.mobileMid) return '5rem'
    if (w > breakpoints.mobileSmall) return '5rem'
    return '5rem'
  }

  const getPaddingLeft = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '16rem'
    if (w > breakpoints.desktopMid) return '8rem'
    if (w > breakpoints.desktopToMobile) return '3rem'
    return '1rem'
  }

  // *** SECTION ***
  const getSection = () => (
    <CardSectionReverse
      title={t(props.cardsNumberTitle)}
      subTitle={t('cards.common.conditionText')}
      text={t(props.cardsNumberCondition)}
      titleColor={themeColors.bg.start}
      icon={<VerifiedSharpIcon sx={{ color: themeColors.bg.start }} />}
      textColor={themeColors.gray.mid}
      description={t(props.cardsNumberDescription)}
      image={props.image}
      imageAlt="Kroemtech"
      imgPosition="100% 50%"
      scrollTop={props.scrollTop}
    />
  )
  const getSection2 = () => (
    <CardSection2
      title={t('cards.common.benefitsText')}
      titleColor={themeColors.bg.start}
      alignedTexts={props.alignedTexts.map(item => ({
        boldText: t(item.boldText),
        text: t(item.text),
        icon: item.icon
      }))}
      textColor={themeColors.gray.mid}
    />
  )

  // *** MAIN RENDER ***
  return (
    <Box
      component="section"
      sx={{
        background: props.background,
        paddingTop: getSectionMarginTop(),
        paddingBottom: getSectionMarginTop(),
        paddingLeft: getPaddingLeft(),
        paddingRight: getPaddingLeft(),
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: props.cardNumber % 2 !== 0 ? 0 : 'auto',
          right: props.cardNumber % 2 === 0 ? 0 : 'auto',
          width: '20%',
          height: '4px',
          backgroundColor: themeColors.gray.mid
        }
      }}
    >
      {getSection()}
      {getSection2()}
    </Box>
  )
}
