import themeColors from '@/assets/theme/colors'

const classes = {
  mainDiv: {
    display: 'flex',
    justifyContent: 'center'
  },
  h1: {
    marginTop: '2rem',
    marginBottom: '2.5rem',
    color: themeColors.gray.dark
  },
  h2: {
    marginTop: '1.25rem',
    marginBottom: '1rem',
    color: themeColors.gray.mid
  },
  b1: {
    fontSize: '0.93rem',
    color: themeColors.gray.mid
  },
  b2: {
    fontSize: '0.93rem',
    color: themeColors.gray.mid,
    marginBottom: '1.875rem'
  },
  ml: {
    marginLeft: '1rem'
  },
  itemTitle: {
    fontSize: '0.93rem',
    color: themeColors.info.main,
    fontWeight: 600
  },
  typoLink: {
    textDecoration: 'none'
  }
}

export default classes
