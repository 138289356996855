import themeColors from 'assets/theme/colors'

const classes = {
  linkBox: {
    cursor: 'pointer',
    paddingTop: '6px'
  },
  upperBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: '16px',
    marginBottom: '-4px'
  },
  underBox: {
    height: '5px',
    background: themeColors.kroemtech.blue,
    opacity: 0.65,
    marginTop: '22px',
    marginLeft: '-20px',
    marginRight: '-20px'
  },
  typoLink: {
    fontSize: '0.937rem',
    textDecoration: 'none',
    color: '#fff'
  },
  typoItem: {
    color: themeColors.gray.mid,
    fontSize: '0.937rem',
    fontWeight: 600
  },
  itemHover: {
    transform: 'scale(1.1)',
    transition: 'transform 100ms repeat'
  },
  logo: {
    height: '25px'
  },

  dropdownClass: {
    height: 19
  },
  dropdownToogle: {
    cursor: 'pointer',
    width: '100%'
  },
  dropdownMenu: {
    background: 'rgba(255, 255, 255, 1)',
    marginTop: 25,
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
    borderRadius: 3,
    marginLeft: '-30px'
  },
  dropdownItemBox: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    minWidth: 380,
    cursor: 'pointer'
  },
  typoToogle: {
    color: '#fff',
    fontFamily: 'inherit',
    fontSize: '0.937rem',
    '&:hover': {
      opacity: 0.9
    },
    '&:active': {
      opacity: 0.85
    }
  },
  imageBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '1.125rem',
    height: 130
  },
  typoBox: {
    paddingLeft: '25px'
  }
}

export default classes
