import { useContext } from 'react'
import { GlobalContext } from 'context/GlobalContextProvider'
import breakpoints from './breakpoints'

function GetFontSize(fontSizes: any) {
  const { screenDimensions } = useContext(GlobalContext)
  const width = screenDimensions.width

  if (width > breakpoints.desktopLarge) return fontSizes.desktopLarge
  if (width > breakpoints.desktopMid) return fontSizes.desktopMid
  if (width > breakpoints.desktopSmall) return fontSizes.desktopSmall
  if (width > breakpoints.desktopToMobile) return fontSizes.desktopToMobile
  if (width > breakpoints.mobileLarge) return fontSizes.mobileLarge
  if (width > breakpoints.mobileMid) return fontSizes.mobileMid
  if (width > breakpoints.mobileSmall) return fontSizes.mobileSmall
  if (width > breakpoints.mobileSmaller) return fontSizes.mobileSmaller
  return fontSizes.default
}

export function getFontH1() {
  return GetFontSize({
    desktopLarge: '3rem',
    desktopMid: '2.75rem',
    desktopSmall: '2.5rem',
    desktopToMobile: '2.25rem',
    mobileLarge: '2rem',
    mobileMid: '1.75rem',
    mobileSmall: '1.5rem',
    mobileSmaller: '1.3rem',
    default: '1.3em'
  })
}

export function getFontH2() {
  return GetFontSize({
    desktopLarge: '2.5rem',
    desktopMid: '2.25rem',
    desktopSmall: '2rem',
    desktopToMobile: '1.75rem',
    mobileLarge: '1.5rem',
    mobileMid: '1.25rem',
    mobileSmall: '1rem',
    mobileSmaller: '0.875rem',
    default: '1em'
  })
}

export function getFontH3() {
  return GetFontSize({
    desktopLarge: '2rem',
    desktopMid: '1.75rem',
    desktopSmall: '1.5rem',
    desktopToMobile: '1.25rem',
    mobileLarge: '1.125rem',
    mobileMid: '1rem',
    mobileSmall: '0.875rem',
    mobileSmaller: '0.75rem',
    default: '1em'
  })
}

export function getFontH4() {
  return GetFontSize({
    desktopLarge: '1.5rem',
    desktopMid: '1.375rem',
    desktopSmall: '1.25rem',
    desktopToMobile: '1.125rem',
    mobileLarge: '1rem',
    mobileMid: '1rem',
    mobileSmall: '1rem',
    mobileSmaller: '1rem',
    default: '1em'
  })
}

export function getFontH5() {
  return GetFontSize({
    desktopLarge: '1.25rem',
    desktopMid: '1.125rem',
    desktopSmall: '1rem',
    desktopToMobile: '1rem',
    mobileLarge: '1rem',
    mobileMid: '1rem',
    mobileSmall: '1rem',
    mobileSmaller: '0.875rem',
    default: '0.875em'
  })
}
