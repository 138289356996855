// React, Next
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useContext, useEffect, useState } from 'react'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'

// Style
import themeColors from '@/assets/theme/colors'
import breakpoints from '@/assets/theme/breakpoints'
import classes from './WelcomeSection-CSS'

export default function WelcomeSection() {
  const { t } = useTranslation('applications')

  const { screenDimensions } = useContext(GlobalContext)
  const [mobileMode, setMobileMode] = useState(false)

  useEffect(() => {
    setMobileMode(screenDimensions.width <= breakpoints.desktopToMobile)
  }, [screenDimensions])

  const getImgHeight = () => {
    const w = screenDimensions.width

    if (w > breakpoints.desktopLarge) return 600
    if (w > breakpoints.mobileLarge) return 500
    if (w > breakpoints.mobileMid) return 500
    if (w > breakpoints.mobileSmall) return 550
    return 580
  }

  const getBoxWidth = () => {
    const w = screenDimensions.width

    if (w > breakpoints.desktopLarge) return '60vw'
    if (w > breakpoints.desktopMid) return '65vw'
    if (w > breakpoints.mobileLarge) return '70vw'
    if (w > breakpoints.mobileSmall) return '80vw'
    return '95vw'
  }

  const getBoxHeight = () => {
    const w = screenDimensions.width

    if (w > breakpoints.desktopLarge) return '600px'
    if (w > breakpoints.desktopToMobile) return '500px'
  }

  const getFontH2 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '2rem'
    if (w > breakpoints.desktopSmall) return '1.875rem'
    if (w > breakpoints.mobileMid) return '1.5rem'
    if (w > breakpoints.mobileSmall) return '1.3rem'
    return '1.3rem'
  }

  const getFontB1 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '1.25rem'
    if (w > breakpoints.desktopToMobile) return '1rem'
    if (w > breakpoints.mobileLarge) return '0.85rem'
    return '0.85rem'
  }

  const getGrid = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopToMobile) return 'span 6'
    return 'span 12'
  }

  const getSection = () => (
    <Box
      gridColumn={getGrid()}
      sx={mobileMode ? classes.subsection2 : classes.subsection}
      style={{
        width: getBoxWidth(),
        height: getBoxHeight()
      }}
    >
      <Typography
        variant="h1"
        fontSize={getFontH2()}
        style={{
          marginBottom: '30px',
          textAlign: 'left',
          lineHeight: 1.5,
          color: themeColors.gray.mid,
          fontWeight: 'bold'
        }}
      >
        {t('applications.title')}
      </Typography>

      <Typography
        variant="body1"
        fontSize={getFontB1()}
        style={{
          color: themeColors.gray.mid,
          textAlign: 'left',
          lineHeight: 1.5,
          maxWidth: '700px'
        }}
      >
        {t('applications.description')}
      </Typography>
    </Box>
  )

  // *** MAIN RENDER ***
  return (
    <Box
      component="section"
      style={classes.section}
      gridTemplateColumns="repeat(12, 1fr)"
    >
      {getSection()}
      <Box sx={classes.imgBox}>
        <Image
          alt="Kroemtech"
          src="https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/applications/apple_pool_wide.webp"
          width={screenDimensions.width}
          height={getImgHeight()}
          style={{
            objectPosition: '65% 0%',
            objectFit: 'cover'
          }}
          priority
        />
      </Box>
    </Box>
  )
}
