// React, Next
import { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'

// Custom
import Link from 'next/link'
import { GlobalContext } from 'context/GlobalContextProvider'
import { translateHref } from 'assets/functions/links'

import themeColors from 'assets/theme/colors'
import breakpoints from 'assets/theme/breakpoints'

// Config
import i18nextConfig from 'next-i18next.config'

// Style
import classes from './GetStarted-CSS'

type PropTypes = {
  title: string
  subTitle: string
  title2?: string
  subTitle2?: string
  button?: string
}
export default function GetStartedSection(props: PropTypes) {
  const { screenDimensions } = useContext(GlobalContext)
  const router = useRouter()
  const currentLocale = router.query.locale || i18nextConfig.i18n.defaultLocale

  const getFontH3 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '2.15rem'
    if (w > breakpoints.desktopSmall) return '2rem'
    if (w > breakpoints.desktopToMobile) return '1.825rem'
    if (w > breakpoints.mobileSmall) return '1.5rem'
    return '1.25rem'
  }

  const getFontH4 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '1.25rem'
    if (w > breakpoints.desktopSmall) return '1.125rem'
    if (w > breakpoints.desktopToMobile) return '1.125rem'
    return '1rem'
  }

  const getPadding = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return `${screenDimensions.width / 15}px`
    if (w > breakpoints.desktopSmall) return `${screenDimensions.width / 20}px`
    if (w > breakpoints.desktopToMobile)
      return `${screenDimensions.width / 25}px`
    return `${screenDimensions.width / 25}px`
  }

  const getMarginTop = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return '4rem'
    if (w > breakpoints.desktopSmall) return '4rem'
    if (w > breakpoints.desktopToMobile) return '3rem'
    return '3rem'
  }

  const getSection = () => (
    <Box
      style={{
        maxWidth: 1000
      }}
    >
      <Typography
        variant="h3"
        fontSize={getFontH3()}
        style={{
          color: themeColors.bg.start,
          textAlign: 'left',
          marginBottom: '1rem',
          fontWeight: 'bold'
        }}
      >
        {props.title}
      </Typography>
      <Typography
        variant="h3"
        fontSize={getFontH4()}
        style={{
          color: themeColors.gray.mid,
          textAlign: 'left',
          marginBottom: '3rem'
        }}
      >
        {props.subTitle}
      </Typography>
      {props.title2 && (
        <>
          <Typography
            variant="h3"
            fontSize={getFontH3()}
            style={{
              color: themeColors.bg.start,
              textAlign: 'left',
              marginBottom: '1rem',
              fontWeight: 'bold'
            }}
          >
            {props.title2}
          </Typography>
          <Typography
            variant="h3"
            fontSize={getFontH4()}
            style={{
              color: themeColors.gray.mid,
              textAlign: 'left'
            }}
          >
            {props.subTitle2}
          </Typography>
        </>
      )}
      {props.button && (
        <Box
          style={{
            marginTop: '4rem',
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Link href={translateHref(currentLocale, 'contact')}>
            <Button
              variant="contained"
              style={{
                minWidth: 150,
                background: themeColors.success.main
              }}
            >
              {props.button}
            </Button>
          </Link>
        </Box>
      )}
    </Box>
  )

  // *** MAIN RENDER ***
  return (
    <Box
      component="section"
      style={classes.section}
      sx={{
        paddingLeft: getPadding(),
        paddingRight: getPadding(),
        marginTop: getMarginTop(),
        marginBottom: getMarginTop()
      }}
    >
      {getSection()}
    </Box>
  )
}
