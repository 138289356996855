// React functions
import React, { useState, ReactElement } from 'react'

// React UI
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import InputBase from '@mui/material/InputBase'
import CheckIcon from '@mui/icons-material/Check'
import Zoom from '@mui/material/Zoom'
import Typography from '@mui/material/Typography'

// Icons
import PersonIcon from '@mui/icons-material/Person'
import EmailIcon from '@mui/icons-material/Email'
import LockIcon from '@mui/icons-material/Lock'
import KeyIcon from '@mui/icons-material/Key'

// todo: change to MUI
// import './FormTextInput.css'

type IconList = 'person' | 'email' | 'check' | 'lock' | 'key'
type TooltipPlacement =
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'bottom-end'
  | 'bottom-start'
  | 'left-end'
  | 'left-start'
  | 'right-end'
  | 'right-start'
  | 'top-end'
  | 'top-start'
  | undefined

type Props = {
  preventCopy?: boolean
  preventPaste?: boolean
  alignment?: 'horizontal' | 'vertical'
  icon?: IconList
  validation?: null | number
  title?: string | ReactElement
  titleFontSize?: number | string
  inputFontSize?: number | string
  tooltip?: string | ReactElement
  tooltipPlacement?: TooltipPlacement
  width?: number
  height?: number
  marginBottom?: number | string
  marginTop?: number | string
  marginRight?: number | string
  marginLeft?: number | string
  iconColor?: string
  color?: string
  placeholder: string
  disabled?: boolean
  inputMarginLeft?: number | string
  inputMarginTop?: number | string
  borderRadius?: number | string
  titleWidth?: number | string
  titleAlignment?: 'left' | 'right'
  type?: 'text' | 'number' | 'password'
  customStyle?: 'standard' | 'outlined'
  value: any
  multiline?: boolean
  rows?: number
  maxRows?: number
  onEnterKeyDown?: () => void
  onClick: () => void
  onBlur: () => void
  onChange: (val: string) => void
}

export default function FormTextInput(props: Props) {
  const [inputFocused, setInputFocused] = useState(false)

  const onChange = (e: any) => {
    props.onChange(e.target.value)
  }
  const onClick = () => {
    props.onClick()
  }
  const onBlur = () => {
    setInputFocused(false)
    props.onBlur()
  }
  const onCopy = (e: any) => {
    if (props.preventCopy) e.preventDefault()
  }
  const onPaste = (e: any) => {
    if (props.preventPaste) e.preventDefault()
  }

  const onEnterKeyDown = (e: any) => {
    if (e.keyCode === 13 && props.onEnterKeyDown !== undefined)
      props.onEnterKeyDown()
  }

  const getIcon = () => {
    switch (props.icon) {
      case 'person':
        return <PersonIcon style={{ transform: 'scale(0.7)' }} />
      case 'email':
        return <EmailIcon style={{ transform: 'scale(0.7)' }} />
      case 'check':
        return <CheckIcon style={{ transform: 'scale(0.7)' }} />
      case 'lock':
        return <LockIcon style={{ transform: 'scale(0.7)' }} />
      case 'key':
        return <KeyIcon style={{ transform: 'scale(0.7)' }} />
    }
  }

  const getBorder = () => {
    if (props.validation === 1) return '1px solid rgb(0,200,0)' // -1 = input rejected, 1 = validated, 0 = default(no input)
    if (props.validation === -1) return '1px solid rgb(200,0,0)'
    if (inputFocused) return '1px solid rgb(90,110,205)'

    if (props.customStyle === 'outlined') {
      if (!props.disabled) return '1px solid #ddd'
      return '0px solid #eee'
    }
    return '1px solid rgb(255,255,255)'
  }

  const getBackgroundColor = () => {
    if (props.alignment === 'horizontal' && props.disabled) return '#ffffff00'
    return '#fff'
  }

  return (
    <Box
      style={{
        display: props.alignment === 'horizontal' ? 'flex' : undefined,
        alignItems: 'center',
        marginBottom: props.marginBottom === undefined ? 0 : props.marginBottom,
        marginTop: props.marginTop === undefined ? 0 : props.marginTop,
        marginRight: props.marginRight === undefined ? 0 : props.marginRight,
        marginLeft: props.marginLeft === undefined ? 0 : props.marginLeft,
        width: props.width === undefined ? '100%' : props.width
      }}
    >
      {props.title === undefined ? null : (
        <Box
          style={{
            display: 'flex',
            justifyContent:
              props.alignment === 'vertical' || props.alignment === undefined
                ? 'left'
                : props.titleAlignment,
            alignItems: 'center',
            background: 'none',
            marginBottom:
              props.alignment === 'vertical' || props.alignment === undefined
                ? '-5px'
                : 0,

            marginLeft: 2,
            marginRight: props.alignment === 'horizontal' ? '10px' : undefined,
            width: props.titleWidth
          }}
        >
          <Typography
            style={{
              fontWeight: 600,
              fontSize:
                props.titleFontSize === undefined
                  ? '0.875rem'
                  : props.titleFontSize
            }}
          >
            {props.title}
          </Typography>
        </Box>
      )}
      <Tooltip
        TransitionComponent={Zoom}
        arrow
        title={
          props.tooltip === undefined ? (
            ''
          ) : (
            <div style={{ fontSize: '0.875rem' }}>{props.tooltip}</div>
          )
        }
        placement={
          props.tooltipPlacement === undefined
            ? 'right'
            : props.tooltipPlacement
        }
        enterDelay={300}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',

            background: getBackgroundColor(),
            borderRadius:
              props.borderRadius === undefined ? 3 : props.borderRadius,
            border: getBorder(),
            transition: 'border ease-in 0.2s',
            marginTop:
              props.alignment === 'vertical' || props.alignment === undefined
                ? 10
                : 0,
            boxShadow:
              props.customStyle === 'outlined'
                ? undefined
                : '0px 1px 2px 0px #00000022'
          }}
        >
          <Box
            style={{
              color: props.iconColor === undefined ? '#666' : props.iconColor
            }}
          >
            {getIcon()}
          </Box>

          <InputBase
            multiline={props.multiline === undefined ? false : props.multiline}
            minRows={props.rows === undefined ? 1 : props.rows}
            maxRows={props.maxRows === undefined ? 1 : props.maxRows}
            placeholder={props.placeholder}
            disabled={props.disabled === undefined ? false : props.disabled}
            style={{
              width: '100%',
              height: props.height === undefined ? 40 : props.height,
              marginBottom: props.inputMarginTop,
              marginTop: props.inputMarginTop
            }}
            type={props.type === undefined ? 'text' : props.type}
            inputProps={{
              style: {
                color: props.color === undefined ? '#666' : props.color,
                fontSize:
                  props.inputFontSize === undefined
                    ? '0.875rem'
                    : props.inputFontSize,
                height: props.height === undefined ? 30 : props.height - 10,
                textAlign: 'left',
                width: '98%',
                background: 'RBGA(0,0,0,0)',
                border: '0px solid #fff',
                paddingLeft:
                  props.inputMarginLeft === undefined
                    ? '10px'
                    : props.inputMarginLeft,
                paddingRight:
                  props.inputMarginLeft === undefined
                    ? '10px'
                    : props.inputMarginLeft
              }
            }}
            value={props.value}
            onFocus={() => setInputFocused(true)}
            onChange={onChange}
            onBlur={onBlur}
            onClick={onClick}
            onCopy={onCopy}
            onPaste={onPaste}
            onKeyDown={onEnterKeyDown}
          />
        </Box>
      </Tooltip>
    </Box>
  )
}
