import themeColors from '@/assets/theme/colors'

const classes = {
  mainDiv: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '3rem'
  },
  sndDiv: {
    maxWidth: 1200,
    padding: '1.25rem'
  },
  h1: {
    marginTop: '2rem',
    marginBottom: '1rem',
    color: themeColors.gray.dark
  },
  h2: {
    marginTop: '2rem',
    marginBottom: '1rem',
    color: themeColors.gray.mid
  },
  b1: {
    fontSize: '0.93rem',
    color: themeColors.gray.mid
  },
  b2: {
    fontSize: '0.93rem',
    color: themeColors.gray.mid,
    marginBottom: '0.5rem'
  },
  ml: {
    marginLeft: '0.875rem'
  },
  bulletsDiv: {
    display: 'flex'
  }
}

export default classes
