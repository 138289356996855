import themeColors from '@/assets/theme/colors'

const classes = {
  mainDiv: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 25px 10px 25px',
    height: 'auto'
  },
  link: {
    color: themeColors.info.main,
    '&:hover': {
      color: themeColors.info.lightest
    }
  }
}

export default classes
