// React, Next
import { ReactElement, useEffect, useRef, useState, RefObject } from 'react'
import { useTranslation } from 'next-i18next'
import { motion } from 'framer-motion'

// Mui
import Box from '@mui/material/Box'

import themeColors from '@/assets/theme/colors'
import { SectionTechScyGateKey } from '@/types/global_types'

// img
import ScyGate from 'public/img/logos/scygate/scygate_logo_white.svg'
import Cloud from 'public/img/logos/scycloud/scycloud_logo_white.svg'
import DathaScy from 'public/img/logos/dathascy/dathaScy_logo_white.svg'

// Sections
import GetStartedSection from '@/components/getStarted/GetStartedSection'
import HowItWorksSection from './sections/HowItWorksSection'
import DescriptionSection from './sections/DescriptionSection'
import HeroSection from './sections/HeroSection'
import WelcomeSection from './sections/WelcomeSection'

// Styles
import classes from './TechnologyView-CSS'

export default function TechnologyView() {
  const { t } = useTranslation('technology')

  const sectionRefs: Record<
    SectionTechScyGateKey,
    RefObject<HTMLDivElement>
  > = {
    howItWorks: useRef<HTMLDivElement>(null),
    cloud: useRef<HTMLDivElement>(null),
    dathascy: useRef<HTMLDivElement>(null),
    description: useRef<HTMLDivElement>(null),
    getStarted: useRef<HTMLDivElement>(null)
  }

  const initialVisibility: Record<SectionTechScyGateKey, boolean> = {
    howItWorks: false,
    cloud: false,
    description: false,
    dathascy: false,
    getStarted: false
  }

  const [visibility, setVisibility] = useState(initialVisibility)

  const updateVisibility = (key: string, isVisible: boolean) => {
    setVisibility(prev => ({ ...prev, [key]: isVisible }))
  }

  useEffect(() => {
    const observers = (Object.keys(sectionRefs) as SectionTechScyGateKey[]).map(
      key => {
        const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => updateVisibility(key, entry.isIntersecting))
        })
        if (sectionRefs[key].current) {
          observer.observe(sectionRefs[key].current as Element)
        }
        return observer
      }
    )

    return () => {
      observers.forEach(observer => observer.disconnect())
    }
  }, [])

  const getSection = (p: {
    section: ReactElement
    reference: RefObject<HTMLDivElement>
    visible: boolean
  }) => (
    <motion.div
      ref={p.reference}
      initial={{ opacity: 0, y: 20 }}
      animate={p.visible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ delay: 0.07, duration: 0.75 }}
    >
      {p.section}
    </motion.div>
  )

  const scrollToSection = (section: SectionTechScyGateKey) => {
    sectionRefs[section]?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Box style={classes.main}>
      <WelcomeSection />
      <HeroSection scrollToSection={scrollToSection} />
      {getSection({
        section: <HowItWorksSection />,
        reference: sectionRefs.howItWorks,
        visible: visibility.howItWorks
      })}
      {getSection({
        section: (
          <DescriptionSection
            image={ScyGate}
            description={t('scygate.body')}
            body={t('scygate.body2')}
            alignedTexts={[
              { text: t('scygate.text1') },
              { text: t('scygate.text2') },
              { text: t('scygate.text3') },
              { text: t('scygate.text4') },
              { text: t('scygate.text5') },
              { text: t('scygate.text6') },
              { text: t('scygate.text7') },
              { text: t('scygate.text8') },
              { text: t('scygate.text9') }
            ]}
            textColor={themeColors.kroemtech.scygate}
          />
        ),
        reference: sectionRefs.description,
        visible: visibility.description
      })}
      {getSection({
        section: (
          <DescriptionSection
            image={Cloud}
            description={t('scycloud.body')}
            body={t('scycloud.body2')}
            alignedTexts={[
              { text: t('scycloud.text1') },
              { text: t('scycloud.text2') },
              { text: t('scycloud.text3') },
              { text: t('scycloud.text4') },
              { text: t('scycloud.text5') },
              { text: t('scycloud.text6') },
              { text: t('scycloud.text7') }
            ]}
            textColor={themeColors.kroemtech.scycloud}
          />
        ),
        reference: sectionRefs.cloud,
        visible: visibility.cloud
      })}
      {getSection({
        section: (
          <DescriptionSection
            image={DathaScy}
            description={t('dathascy.body')}
            body={t('dathascy.body2')}
            alignedTexts={[
              { text: t('dathascy.text1') },
              { text: t('dathascy.text2') },
              { text: t('dathascy.text3') },
              { text: t('dathascy.text4') },
              { text: t('dathascy.text5') }
            ]}
            textColor={themeColors.kroemtech.dathascy}
          />
        ),
        reference: sectionRefs.dathascy,
        visible: visibility.dathascy
      })}
      {getSection({
        section: (
          <GetStartedSection
            title={t('started.title')}
            subTitle={t('started.subTitle')}
            button={t('started.button')}
          />
        ),
        reference: sectionRefs.getStarted,
        visible: visibility.getStarted
      })}
    </Box>
  )
}
