import themeColors from '@/assets/theme/colors'

const classes = {
  section: {
    display: 'flex',
    justifyContent: 'left',
    background: themeColors.gray.lightest
  },
  subsection: {
    background: 'rgba(255, 255, 255, 0.8)',
    padding: '10rem 15rem 10rem 3rem',
    zIndex: 1,
    position: 'absolute',
    clipPath: 'polygon(0px 0px, 70% 0px, 100% 100%, 0% 100%)',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      clipPath: 'polygon(70% 0px, 100% 100%, 90% 100%, 60% 0px)', // Ajuste conforme necessário
      boxShadow: 'inset #00000042 -160px 40px 200px 100px', // Sombra mais opaca
      pointerEvents: 'none' // Evita que o pseudo-elemento interfira nos eventos do mouse
    }
  },
  subsection2: {
    background: 'rgba(255, 255, 255, 0.8)',
    padding: '1rem',
    zIndex: 1,
    position: 'absolute',
    marginTop: '8rem',
    right: 0
  },
  subsectionDiv: {
    zIndex: 2
  },
  linkDiv: {
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'center'
  },
  linkButton: {
    minWidth: 150,
    background: themeColors.kroemtech.blue,
    zIndex: 2
  },
  imgBox: {
    width: '100%',
    height: '100%'
  }
}

export default classes
