// React functions
import { ReactElement, useContext } from 'react'

// React UI
import Divider from '@mui/material/Divider'
import Modal from 'react-modal'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

// Icons
import InfoIcon from '@mui/icons-material/Info'
import DangerousIcon from '@mui/icons-material/Dangerous' // error
import WarningIcon from '@mui/icons-material/Warning'
import CheckCircleIcon from '@mui/icons-material/CheckCircle' // success

// Style
import themeColors from 'assets/theme/colors'
import { GlobalContext } from 'context/GlobalContextProvider'
import classes from './InfoMessageModal-CSS'

type Props = {
  mustConfirm?: boolean
  show: boolean
  buttonCaption?: string | ReactElement
  content: {
    mode: 'info' | 'success' | 'warning' | 'error'
    title: string | ReactElement
    message: string | ReactElement
  }
  width?: number | string
  height?: number | string
  close: () => void
}

export default function InfoMessageModal(props: Props) {
  const { screenDimensions } = useContext(GlobalContext)

  // * Modal Functions *
  const onClickOutside = () => {
    if (!props.mustConfirm) props.close()
  } // close the modal by clicking outside of it

  const onCancel = () => {
    props.close()
  } // close the modal using cancel button
  // * Modal style *
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      top: 0,
      left: 0,
      zIndex: 1000
    },
    content: {
      width: props.width !== undefined ? props.width : 310,
      height: 'auto',
      display: 'block',
      background: '#fff',
      borderRadius: 3,
      padding: '15px',
      marginTop: screenDimensions.height / 2 - Number(props.height),
      marginLeft: screenDimensions.width / 2 - Number(props.width)
    }
  }

  const getHeaderColor = () => {
    switch (props.content.mode) {
      case 'info':
        return themeColors.info.main
      case 'success':
        return themeColors.success.main
      case 'warning':
        return themeColors.warning.main
      case 'error':
        return themeColors.error.main
    }
  }

  const getIcon = () => {
    switch (props.content.mode) {
      case 'info':
        return <InfoIcon style={{ color: getHeaderColor() }} />
      case 'success':
        return <CheckCircleIcon style={{ color: getHeaderColor() }} />
      case 'warning':
        return <WarningIcon style={{ color: getHeaderColor() }} />
      case 'error':
        return <DangerousIcon style={{ color: getHeaderColor() }} />
    }
  }

  return (
    <Modal
      isOpen={props.show}
      onRequestClose={onClickOutside}
      shouldCloseOnEsc
      ariaHideApp={false}
      style={customStyles}
      className="mymodal"
      closeTimeoutMS={200}
    >
      <Box style={classes.titleBox}>
        <Typography style={classes.typoHeader} sx={{ color: getHeaderColor() }}>
          {props.content.title}
        </Typography>
        {getIcon()}
      </Box>
      <Divider component="div" style={classes.divider} />

      <Box
        style={classes.formBox}
        sx={{
          height: props.height !== undefined ? props.height : 280
        }}
      >
        <Typography style={classes.typo}>{props.content.message}</Typography>
      </Box>

      <Stack
        direction="row"
        spacing={2}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Button
          variant="contained"
          style={{
            outline: 'none',
            background: themeColors.gray.mid
          }}
          onClick={onCancel}
        >
          <Typography style={classes.typoButton}>
            {props.buttonCaption === undefined ? 'OK' : props.buttonCaption}
          </Typography>
        </Button>
      </Stack>
    </Modal>
  )
}
