// React, Next
import { useRouter } from 'next/router'
import Link from 'next/link'

// MUI
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'

// Custom
import languageDetector from 'lib/languageDetector'

// Style
import classes from './LanguageSwitcher2-CSS'
import getSwitcherHref from './LanguageSwitcherRouter'

function LanguageSwitcher2({
  currentLocale,
  ...rest
}: {
  [x: string]: any
  currentLocale: string | string[]
}) {
  const router: any = useRouter()

  const getHref = (btn: string) => {
    let href = rest.href || router.asPath
    const pName = router.pathname
    const hrefBase = `/${btn}/`

    href = getSwitcherHref(hrefBase, btn, pName, href)

    /*
    Object.keys(router.query).forEach((k) => {
      if (k === 'locale') {
        pName = pName.replace(`[${k}]`, btn)
        return
      }
      pName = pName.replace(`[${k}]`, router.query[k])
    })
    if (btn) {
      href = rest.href ? `/${btn}${rest.href}` : pName
    }
    */
    return href
  }

  const switchLang = (btn: string) => {
    if (btn !== currentLocale) {
      if (languageDetector.cache) {
        languageDetector.cache(btn)
      }
    }
    return null
  }

  return (
    <Box sx={classes.mainBox}>
      <Link href={getHref('en')}>
        <Box
          sx={classes.linkBox}
          style={{
            fontSize: 16
          }}
          onClick={() => switchLang('en')}
        >
          <Typography>EN</Typography>
        </Box>
      </Link>
      <div style={classes.separator}>
        <Typography>|</Typography>
      </div>
      <Link href={getHref('de')}>
        <Box
          sx={classes.linkBox}
          style={{
            fontSize: 16
          }}
          onClick={() => switchLang('de')}
        >
          <Typography>DE</Typography>
        </Box>
      </Link>
    </Box>
  )
}

export default LanguageSwitcher2
