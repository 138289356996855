// React, Next
import { useTranslation } from 'next-i18next'
import { useContext } from 'react'
import Image, { StaticImageData } from 'next/image'

// MUI
import { Box, styled, Typography } from '@mui/material'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

// SVG
import arrowsVertical from 'public/img/technology/arrowsVertical.svg'
import arrowsVertical2 from 'public/img/technology/arrowsVertical2.svg'
import arrowsSet from 'public/img/technology/arrowsSet.svg'
import arrowsSet2 from 'public/img/technology/arrowsSet2.svg'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'

// Types
import { SectionTechScyGateKey } from '@/types/global_types'

// Img
import ScyGate from 'public/img/logos/scygate/scygate_logo_white.svg'
import ScyCloud from 'public/img/logos/scycloud/scycloud_logo_white.svg'
import DathaScy from 'public/img/logos/dathascy/dathaScy_logo_white.svg'
import Device from 'public/img/technology/scygate_device.png'
import Cloud from 'public/img/technology/cloud.png'
import Front from 'public/img/technology/frontend.png'

// Style
import breakpoints from '@/assets/theme/breakpoints'
import themeColors from '@/assets/theme/colors'
import classes from './HeroSection-CSS'

const getIndicator = (img: any) => (
  <Image
    alt=""
    src={img}
    style={{
      objectFit: 'cover',
      objectPosition: '50% 50%'
    }}
  />
)

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: '0 3px 10px rgba(0,0,0,.15)',
    textAlign: 'center'
  }
}))

type Props = {
  scrollToSection: (section: SectionTechScyGateKey) => void
}

export default function HeroSection(props: Props) {
  const { t } = useTranslation('technology')
  const { screenDimensions } = useContext(GlobalContext)
  const w = screenDimensions.width

  const getPadding = () => {
    if (w > breakpoints.desktopLarge) return '16rem'
    if (w > breakpoints.desktopMid) return '8rem'
    if (w > breakpoints.desktopToMobile) return '3rem'
    return '1rem'
  }

  const getFontB1 = () => {
    if (w > breakpoints.desktopToMobile) return '1.4rem'
    if (w > breakpoints.mobileLarge) return '1.3rem'
    if (w > breakpoints.mobileMid) return '1.1rem'
    return '1rem'
  }

  const getBoxImage = (p: {
    logo: StaticImageData
    image: StaticImageData
    width: number
    tooltipTitle: string
    tooltipText: string
    sectionKey: SectionTechScyGateKey
    height?: number
    space?: string
  }) => (
    <HtmlTooltip
      title={
        <>
          <Typography sx={{ fontWeight: 'bold' }}>{p.tooltipTitle}</Typography>
          <Typography>{p.tooltipText}</Typography>
        </>
      }
    >
      <Box
        sx={classes.boxImage}
        onClick={() => props.scrollToSection(p.sectionKey)}
      >
        <Image
          alt=""
          src={p.logo}
          style={{
            objectFit: 'cover',
            objectPosition: '50% 50%'
          }}
          width={200}
        />
        <Image
          alt=""
          src={p.image}
          style={{
            objectFit: 'contain',
            objectPosition: '50% 50%',
            marginTop: p.space
          }}
          width={p.width}
          height={p.height}
        />
      </Box>
    </HtmlTooltip>
  )

  const getSection = () => (
    <Box sx={classes.section}>
      <Box
        sx={{
          position: 'absolute',
          top: '38%',
          left: '0%'
        }}
      >
        {getBoxImage({
          logo: ScyGate,
          image: Device,
          width: 250,
          tooltipTitle: 'ScyGate',
          tooltipText: t('tooltip.scygate'),
          sectionKey: 'description',
          height: 160,
          space: '5px'
        })}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '5%',
          left: '36%'
        }}
      >
        {getBoxImage({
          logo: ScyCloud,
          image: Cloud,
          width: 250,
          tooltipTitle: 'ScyCloud',
          tooltipText: t('tooltip.scycloud'),
          sectionKey: 'cloud'
        })}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '38%',
          right: '0%',
          width: '250px'
        }}
      >
        {getBoxImage({
          logo: DathaScy,
          image: Front,
          width: 250,
          tooltipTitle: 'DathaScy',
          tooltipText: t('tooltip.dathascy'),
          sectionKey: 'dathascy',
          space: '15px'
        })}
      </Box>
      <Box
        sx={{
          ...classes.boxText,
          top: '85%',
          right: '0%'
        }}
      >
        <Typography sx={{ color: themeColors.gray.mid, fontSize: getFontB1() }}>
          {t('dathascy.name')}
        </Typography>
      </Box>
      <Box
        sx={{
          ...classes.boxText,
          top: '85%',
          left: '0%'
        }}
      >
        <Typography sx={{ color: themeColors.gray.mid, fontSize: getFontB1() }}>
          {t('scygate.name')}
        </Typography>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: '24%',
          left: '32%',
          transform: 'scale(1.2) rotate(27deg)'
        }}
      >
        {getIndicator(arrowsSet)}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '25%',
          left: '59%',
          transform: 'scale(1.2) rotate(334deg)'
        }}
      >
        {getIndicator(arrowsSet2)}
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: '58%',
          left: '9%',
          transform: 'scale(0.25)'
        }}
      >
        {getIndicator(arrowsVertical)}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '58%',
          left: '81%',
          transform: 'scale(0.25)'
        }}
      >
        {getIndicator(arrowsVertical2)}
      </Box>
    </Box>
  )

  // *** MAIN RENDER ***
  return (
    <Box
      component="section"
      sx={{
        ...classes.thinScrollbar,
        overflowX: 'auto',
        display: 'flex',
        justifyContent:
          w > breakpoints.desktopToMobile ? 'center' : 'flex-start',
        paddingLeft: getPadding(),
        paddingRight: getPadding(),
        paddingTop: '2rem'
      }}
    >
      {getSection()}
    </Box>
  )
}
