// React, Next
import { ReactNode, useContext, useEffect, useRef, useState } from 'react'
import Image, { StaticImageData } from 'next/image'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'
import AlignedText from 'components/alignedText/AlignedText'

// Style
import breakpoints from 'assets/theme/breakpoints'
import classes from './CardSectionReverse-CSS'

type PropTypes = {
  title: string
  subTitle?: string
  titleColor: string
  icon: ReactNode
  text: string
  textColor: string
  description: string
  image: StaticImageData | string
  imageAlt: string
  imgPosition?: string
  scrollTop: () => void
}
export default function CardSectionReverse(props: PropTypes) {
  const [mobileMode, setMobileMode] = useState(false)
  const { screenDimensions } = useContext(GlobalContext)
  useEffect(() => {
    setMobileMode(screenDimensions.width <= breakpoints.desktopToMobile)
  }, [screenDimensions])

  const BoxRef: any = useRef(null)

  const getFontH1 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '1.625rem'
    if (w > breakpoints.desktopSmall) return '1.625rem'
    if (w > breakpoints.mobileMid) return '1.5rem'
    if (w > breakpoints.mobileSmall) return '1.4rem'
    return '1.2rem'
  }

  const getFontH5 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '1.1rem'
    if (w > breakpoints.desktopMid) return '1.1rem'
    if (w > breakpoints.desktopSmall) return '1.1rem'
    if (w > breakpoints.desktopToMobile) return '1rem'
    if (w > breakpoints.mobileLarge) return '1rem'
    if (w > breakpoints.mobileMid) return '1rem'
    if (w > breakpoints.mobileSmall) return '0.9rem'
    if (w > breakpoints.mobileSmaller) return '0.8rem'
    return '0.8rem'
  }

  const getGrid = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopToMobile) return 'span 6'
    return 'span 12'
  }

  const getPhotoHeight = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopToMobile) return 400
    return 300
  }

  const getPhotoWidth = () => {
    let w = 0
    try {
      if (BoxRef.current) w = BoxRef.current.clientWidth
    } catch {
      w = 500
    }
    return w
  }

  return (
    <>
      <Box sx={classes.titleBox}>
        <Typography
          variant="h1"
          fontSize={getFontH1()}
          sx={{
            marginBottom: !mobileMode ? '2rem' : '2.5rem',
            marginLeft: !mobileMode ? '3rem' : '0.5rem',
            textAlign: !mobileMode ? 'left' : 'left',
            color: props.titleColor,
            fontWeight: 'bold'
          }}
        >
          {props.title}
        </Typography>
        <Box onClick={props.scrollTop} sx={classes.iconBox}>
          <KeyboardDoubleArrowUpIcon />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          gap: mobileMode ? 3 : 6
        }}
      >
        <Box
          gridColumn={getGrid()}
          ref={BoxRef}
          id="applicationImgBox"
          style={{
            justifyContent: !mobileMode ? 'left' : 'center'
          }}
        >
          <Image
            alt={props.imageAlt}
            src={props.image}
            width={getPhotoWidth()}
            height={getPhotoHeight()}
            style={{
              borderRadius: mobileMode ? 0 : '5px',
              objectFit: 'cover',
              objectPosition: props.imgPosition ?? '50% 50%'
            }}
            priority={false}
          />
        </Box>
        <Box
          gridColumn={getGrid()}
          sx={{
            ...classes.section1,
            justifyContent: !mobileMode ? 'left' : 'center'
          }}
        >
          <Box
            style={{
              paddingLeft:
                screenDimensions.width <= breakpoints.desktopToMobile
                  ? '1rem'
                  : 0,
              paddingRight:
                screenDimensions.width <= breakpoints.desktopToMobile
                  ? '1rem'
                  : 0
            }}
          >
            <Typography
              variant="h5"
              sx={{
                marginBottom: '1.5rem',
                textAlign: 'left',
                color: props.textColor,
                fontWeight: '400',
                fontSize: getFontH5()
              }}
            >
              {props.description}
            </Typography>
            <AlignedText
              icon={props.icon}
              boldText={props.subTitle}
              textColor={props.textColor}
              description={props.text}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
