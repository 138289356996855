// React, Next
import { useTranslation } from 'next-i18next'
import { useContext } from 'react'
import { useRouter } from 'next/router'

// MUI
import Box from '@mui/material/Box'
import { Divider, Typography } from '@mui/material'

// Next
import Image, { StaticImageData } from 'next/image'
import Link from 'next/link'

// Images
import Img0001 from 'public/img/articles/data_collection4.webp'
import Img0002 from 'public/img/articles/challenge_in_data_collection.webp'
import Img0003a from 'public/img/articles/data_monitoring_1.webp'
import Img0003b from 'public/img/articles/data_monitoring_2.webp'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'

// Translation
import { PageType, translateHref } from '@/assets/functions/links'
import nextI18nextConfig from 'next-i18next.config'

// Style
import breakpoints from 'assets/theme/breakpoints'
import classes from './ArticlesView-CSS'

export default function ArticlesView() {
  const router = useRouter()
  const currentLocale =
    router.query.locale || nextI18nextConfig.i18n.defaultLocale

  const { t } = useTranslation('articles')

  const { screenDimensions } = useContext(GlobalContext)

  const getMobileLayout = (p: {
    img: StaticImageData
    imgPosition?: string
    title: string
    description: string
    hrefTarget: PageType
  }) => (
    <Box style={{ margin: '30px 0 30px 0' }}>
      <Link
        href={translateHref(currentLocale, p.hrefTarget)}
        style={classes.typoLink}
      >
        <Typography variant="h2" sx={classes.h2mob}>
          {p.title}
        </Typography>
      </Link>
      <Box style={{ marginBottom: '25px' }}>
        <Link
          href={translateHref(currentLocale, p.hrefTarget)}
          style={classes.typoLink}
        >
          <Image
            alt=""
            src={p.img}
            style={{
              boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 3px 0px',
              height:
                (screenDimensions.width > 800 ? 800 : screenDimensions.width) /
                4,
              width: screenDimensions.width > 800 ? 800 : '100%',
              objectFit: 'cover',
              objectPosition: p.imgPosition
            }}
            priority
          />
        </Link>
      </Box>
      <Typography variant="body1" sx={classes.body1mob}>
        {p.description}
      </Typography>
      <Link
        href={translateHref(currentLocale, p.hrefTarget)}
        style={classes.typoLink}
      >
        <Typography variant="body1" sx={classes.body1mob}>
          {t('readMore')}
        </Typography>
      </Link>
    </Box>
  )

  const getDeskLayout = (p: {
    img: StaticImageData
    imgPosition?: string
    title: string
    description: string
    hrefTarget: PageType
  }) => (
    <Box style={{ display: 'flex', margin: '30px 0 30px 0' }}>
      <Box>
        <Link
          href={translateHref(currentLocale, p.hrefTarget)}
          style={classes.typoLink}
        >
          <Image
            alt=""
            src={p.img}
            style={{
              boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 3px 0px',
              height: 200,
              width: 200,
              objectFit: 'cover',
              objectPosition: p.imgPosition
            }}
            priority
          />
        </Link>
      </Box>
      <Box style={{ marginLeft: '1.5rem' }}>
        <Link
          href={translateHref(currentLocale, p.hrefTarget)}
          style={classes.typoLink}
        >
          <Typography variant="h2" sx={classes.h2desk}>
            {p.title}
          </Typography>
        </Link>
        <Typography variant="body1" sx={classes.body1desk}>
          {p.description}
        </Typography>
        <Link
          href={translateHref(currentLocale, p.hrefTarget)}
          style={classes.typoLink}
        >
          <Typography variant="body1" sx={classes.body1desk}>
            {t('readMore')}
          </Typography>
        </Link>
      </Box>
    </Box>
  )

  return (
    <Box
      sx={classes.mainDiv}
      style={{
        marginTop:
          screenDimensions.width > breakpoints.desktopToMobile ? '5rem' : '3rem'
      }}
    >
      <Box
        sx={classes.sndDiv}
        style={{
          minHeight: screenDimensions.height - 220
        }}
      >
        <Typography
          variant="h1"
          sx={classes.h1}
          style={{
            fontSize:
              screenDimensions.width > breakpoints.mobileSmall
                ? '2.125rem'
                : '1.875rem'
          }}
        >
          {t('h1')}
        </Typography>

        <section>
          {screenDimensions.width < breakpoints.mobileLarge ? (
            <>
              {getMobileLayout({
                img: Img0001,
                imgPosition: '50% 20%',
                title: t('a0001.title'),
                description: t('a0001.description'),
                hrefTarget: 'A0001'
              })}
              <Divider light />
              {getMobileLayout({
                img: Img0002,
                title: t('a0002.title'),
                description: t('a0002.description'),
                hrefTarget: 'A0002'
              })}
              <Divider light />
              {getMobileLayout({
                img: Img0003a,
                title: t('a0003.title'),
                description: t('a0003.description'),
                hrefTarget: 'A0003'
              })}
            </>
          ) : (
            <>
              {getDeskLayout({
                img: Img0001,
                imgPosition: '50% 0',
                title: t('a0001.title'),
                description: t('a0001.description'),
                hrefTarget: 'A0001'
              })}
              <Divider light />
              {getDeskLayout({
                img: Img0002,
                imgPosition: '45% 0%',
                title: t('a0002.title'),
                description: t('a0002.description'),
                hrefTarget: 'A0002'
              })}
              <Divider light />
              {getDeskLayout({
                img: Img0003b,
                imgPosition: '20% 0%',
                title: t('a0003.title'),
                description: t('a0003.description'),
                hrefTarget: 'A0003'
              })}
            </>
          )}
        </section>
      </Box>
    </Box>
  )
}
