import themeColors from '@/assets/theme/colors'

const classes = {
  subsection: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '50px',
    zIndex: 2
  },
  subsection2: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '50px',
    justifyContent: 'right',
    paddingRight: '10vw',
    marginTop: '-15vh',
    zIndex: 2
  },
  subsectionDiv: {
    zIndex: 10
  },
  linkDiv: {
    marginTop: '4rem',
    display: 'flex'
  },
  linkButton: {
    minWidth: 150,
    background: themeColors.success.darker,
    zIndex: 2,
    '&:hover': {
      background: themeColors.success.main
    }
  }
}

export default classes
