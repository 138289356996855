const themeColors = {
  kroemtech: {
    green: '#0BE318',
    greenBlue: '#00E362',
    blueGreen: '#0BE3B9',
    blue: '#00C6EF',
    lightBlue: '#54eeff',
    background: '#ffffff',
    sensBlue: '#606EA0',
    dunkelblau: '#121139',
    scygate: '#e60055',
    dathascy: '#02d159',
    scycloud: '#11a5f5'
  },

  bg: {
    start: '#5a6dcd',
    end: '#463264'
  },

  gray: {
    lightest: '#f5f5f5',
    lighter: '#dcdcdc',
    light: '#c8c8c8',
    mid: '#646464',
    darker: '#454545',
    dark: '#323232'
  },

  success: {
    main: '#2dce89',
    darker: '#07b86c'
  },

  info: {
    lightest: '#ebf1fc',
    main: '#0059ff'
  },

  error: {
    main: '#ff3246'
  },

  warning: {
    main: '#ff9500',
    light: '#f7e011'
  }
}

export default themeColors
