import React from 'react'
import themeColors from '@/assets/theme/colors'

type Props = {
  // scale?: number,
  color: 1 | 2 | 3 | 4

  // borderRadius?: number,
  // rotation? : number
  // zIndex?: number
  opacity?: number
  variant: 1 | 2 | 3
  marginTop?: number
  marginLeft?: number
}

export default function KroemtechSingleRectangleSvg(props: Props) {
  const getColor = () => {
    if (props.color === 1) return themeColors.kroemtech.green
    if (props.color === 2) return themeColors.kroemtech.greenBlue
    if (props.color === 3) return themeColors.kroemtech.blueGreen
    return themeColors.kroemtech.blue
  }

  const getVariant = () => {
    if (props.variant === 1) {
      return (
        <svg width="400" height="612">
          <g>
            <title>Layer 1</title>
            <rect
              transform="rotate(135 -57.0347 430.457)"
              strokeWidth="0"
              id="svg_2"
              height="325"
              width="875"
              y="267.95691"
              x="-494.53467"
              stroke={getColor()}
              fill={getColor()}
            />
          </g>
        </svg>
      )
    }
    if (props.variant === 2) {
      return (
        <svg width="400" height="612">
          <g>
            <title>Layer 1</title>
            <rect
              transform="rotate(45 -47.9438 185.004)"
              strokeWidth="0"
              id="svg_2"
              height="325"
              width="875"
              y="22.50447"
              x="-485.44383"
              stroke={getColor()}
              fill={getColor()}
            />
          </g>
        </svg>
      )
    }
    return (
      <svg width="400" height="612">
        <g>
          <title>Layer 1</title>
          <rect
            transform="rotate(135 463.415 171.368)"
            strokeWidth="0"
            id="svg_2"
            height="325"
            width="875"
            y="8.86823"
            x="25.91539"
            stroke={getColor()}
            fill={getColor()}
          />
        </g>
      </svg>
    )
  }

  return (
    <div
      style={{
        position: 'absolute',
        opacity: props.opacity === undefined ? 0.5 : props.opacity,
        marginTop: props.marginTop === undefined ? 0 : props.marginTop,
        marginLeft: props.marginLeft === undefined ? 0 : props.marginLeft,
        zIndex: 0
      }}
    >
      {getVariant()}
    </div>
  )
}
