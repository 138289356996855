// React functions
import React, { useContext, useRef } from 'react'
import Modal from 'react-modal'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'

// React UI
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'

// Style
import breakpoints from '@/assets/theme/breakpoints'
import classes from './GpdrModal-CSS'

type Props = {
  show: boolean
  onAccept: () => void
}

export default function GpdrModal(props: Props) {
  const { t } = useTranslation('common')
  const { screenDimensions } = useContext(GlobalContext)
  const ref: any = useRef()
  const ref2: any = useRef()

  const text = (
    <>
      {t('gpdr.message1')}{' '}
      <Link
        href="/privacy"
        rel="noopener noreferrer"
        style={{ ...classes.link, textDecoration: 'none' }}
      >
        {t('gpdr.message2')}
      </Link>
    </>
  )

  const getHeight = () => {
    try {
      const h = ref2.current.getBoundingClientRect().height
      const offset = screenDimensions.width > breakpoints.mobileLarge ? 0 : 50
      return h + 50 + offset
    } catch {
      return 50
    }
  }

  const getTop = () => {
    try {
      const h = getHeight()
      return screenDimensions.height - h
    } catch {
      return 50
    }
  }

  // * Modal style *
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
      height: screenDimensions.height,
      width: screenDimensions.width,
      top: getTop(),
      left: 0,
      zIndex: 2000
    },
    content: {
      height: getHeight(),
      width: screenDimensions.width,
      display: 'flex',
      justifyContent: 'center',
      bottom: 0,
      padding: 0,
      background: '#fff',
      borderRadius: 0,
      boxShadow: '0px -3px 7px 0px #ccc',
      outline: 'none'
    }
  }

  return (
    <Modal
      isOpen={props.show}
      ariaHideApp={false}
      style={customStyles}
      className="mymodal"
      closeTimeoutMS={200}
    >
      {screenDimensions.width > breakpoints.mobileLarge ? (
        <Box
          ref={ref}
          style={{
            ...classes.mainDiv,
            display: 'flex'
          }}
        >
          <Box
            ref={ref2}
            style={{
              display: 'flex',
              justifyContent: 'right'
            }}
          >
            <Typography>{text}</Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'right',
              marginLeft: '10px'
            }}
          >
            <Button variant="contained" color="info" onClick={props.onAccept}>
              OK
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          ref={ref}
          style={{
            ...classes.mainDiv,
            display: 'block'
          }}
        >
          <Box
            ref={ref2}
            style={{
              display: 'flex',
              marginBottom: '20px'
            }}
          >
            <Typography>{text}</Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Button onClick={props.onAccept} variant="contained" color="info">
              OK
            </Button>
          </Box>
        </Box>
      )}
    </Modal>
  )
}
