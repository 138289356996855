const classes = {
  section: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '6rem',
    paddingBottom: '80px'
  },
  section2: {
    paddingTop: '50px',
    paddingBottom: '80px'
  },
  cardBox: {
    display: 'grid'
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '10px'
  }
}

export default classes
