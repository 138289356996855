// React, Next
import { useTranslation } from 'next-i18next'
import { useContext } from 'react'

// MUI
import Box from '@mui/material/Box'
import VerifiedSharpIcon from '@mui/icons-material/VerifiedSharp'

// Custom
import SensorsSharpIcon from '@mui/icons-material/SensorsRounded'
import IntegrationInstructionsRoundedIcon from '@mui/icons-material/IntegrationInstructionsRounded'
import StorageRoundedIcon from '@mui/icons-material/StorageRounded'
import { GlobalContext } from 'context/GlobalContextProvider'
import themeColors from 'assets/theme/colors'
import breakpoints from 'assets/theme/breakpoints'
import CardSection from '@/components/cards/cardSection/CardSection'
import CardSection2 from '@/components/cards/cardSection2/CardSection2'

// Img
import Custom from 'public/img/services/sensor_pcb_w1350.webp'

// Config

// Style
import classes from './DataCollectionSection-CSS'

export default function DataCollectionSection() {
  const { t } = useTranslation('services')
  const { screenDimensions } = useContext(GlobalContext)

  const getSectionMarginTop = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '6rem'
    if (w > breakpoints.desktopMid) return '4rem'
    if (w > breakpoints.desktopSmall) return '4rem'
    if (w > breakpoints.desktopToMobile) return '5rem'
    if (w > breakpoints.mobileMid) return '5rem'
    if (w > breakpoints.mobileSmall) return '5rem'
    return '5rem'
  }

  const getSectionPadding = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '12rem'
    if (w > breakpoints.desktopMid) return '6rem'
    if (w > breakpoints.desktopToMobile) return '3rem'
    return '1rem'
  }

  // *** SECTION ***
  const getSection = () => (
    <CardSection
      title={t('cards.02.title')}
      titleColor={themeColors.bg.start}
      icon={<VerifiedSharpIcon sx={{ color: themeColors.bg.start }} />}
      text={[t('cards.02.subtitle.01'), t('cards.02.subtitle.02')]}
      textColor={themeColors.gray.mid}
      description={t('cards.02.description')}
      image={Custom}
      imageAlt="Kroemtech"
      module="2"
    />
  )
  const getSection2 = () => (
    <CardSection2
      title={t('cards.benefitsText')}
      titleColor={themeColors.bg.start}
      alignedTexts={[
        {
          boldText: t('cards.02.benefits.bold01'),
          text: t('cards.02.benefits.01'),
          icon: <SensorsSharpIcon sx={{ color: themeColors.bg.start }} />
        },
        {
          boldText: t('cards.02.benefits.bold02'),
          text: t('cards.02.benefits.02'),
          icon: (
            <IntegrationInstructionsRoundedIcon
              sx={{ color: themeColors.bg.start }}
            />
          )
        },
        {
          boldText: t('cards.02.benefits.bold03'),
          text: t('cards.02.benefits.03'),
          icon: <StorageRoundedIcon sx={{ color: themeColors.bg.start }} />
        }
      ]}
      textColor={themeColors.gray.mid}
    />
  )

  // *** MAIN RENDER ***
  return (
    <Box
      component="section"
      sx={{
        ...classes.section,
        gap: screenDimensions.width <= breakpoints.desktopToMobile ? 0 : '3rem',
        paddingTop: getSectionMarginTop(),
        paddingBottom: getSectionMarginTop(),
        paddingLeft: getSectionPadding(),
        paddingRight: getSectionPadding()
      }}
      gridTemplateColumns="repeat(12, 1fr )"
    >
      {getSection()}
      {getSection2()}
    </Box>
  )
}
