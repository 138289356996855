// React, Next
import { useContext, useState, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'

// MUI
import Box from '@mui/material/Box'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'
import { translateHref } from 'assets/functions/links'

// Style
import breakpoints from '@/assets/theme/breakpoints'
import classes from './MainMenu-CSS'

type Props = {
  locale: string | string[]
}

export default function MainMenu(props: Props) {
  const { screenDimensions } = useContext(GlobalContext)

  const { t } = useTranslation('common')

  const [serviceHover, setServiceHover] = useState(false)
  const [aboutHover, setAboutHover] = useState(false)
  const [contactHover, setContactHover] = useState(false)
  const [technologyHover, setTechnologyHover] = useState(false)

  const getContactMarginRight = () => {
    if (screenDimensions.width > breakpoints.desktopMid) return '100px'
    if (screenDimensions.width > breakpoints.desktopSmall) return '50px'
    return '30px'
  }

  return (
    <>
      <Link
        href={translateHref(props.locale, 'technology')}
        style={classes.typoLink}
      >
        <Box
          sx={classes.linkBox}
          style={{
            paddingLeft:
              screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px',
            paddingRight:
              screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px'
          }}
          onMouseEnter={() => setTechnologyHover(true)}
          onMouseLeave={() => setTechnologyHover(false)}
        >
          <Box sx={classes.upperBox}>{t('menu.technology')}</Box>
          <Box
            sx={classes.underBox}
            style={{
              opacity: !technologyHover ? 0 : 1
            }}
          />
        </Box>
      </Link>

      <Link
        href={translateHref(props.locale, 'services')}
        style={classes.typoLink}
      >
        <Box
          sx={classes.linkBox}
          style={{
            paddingLeft:
              screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px',
            paddingRight:
              screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px'
          }}
          onMouseEnter={() => setServiceHover(true)}
          onMouseLeave={() => setServiceHover(false)}
        >
          <Box sx={classes.upperBox}>{t('menu.services')}</Box>
          <Box
            sx={classes.underBox}
            style={{
              opacity: !serviceHover ? 0 : 1
            }}
          />
        </Box>
      </Link>

      <Link
        href={translateHref(props.locale, 'applications')}
        style={classes.typoLink}
      >
        <Box
          sx={classes.linkBox}
          style={{
            paddingLeft:
              screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px',
            paddingRight:
              screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px'
          }}
          onMouseEnter={() => setAboutHover(true)}
          onMouseLeave={() => setAboutHover(false)}
        >
          <Box sx={classes.upperBox}>{t('menu.applications')}</Box>
          <Box
            sx={classes.underBox}
            style={{
              opacity: !aboutHover ? 0 : 1
            }}
          />
        </Box>
      </Link>

      <Link
        href={translateHref(props.locale, 'contact')}
        style={classes.typoLink}
      >
        <Box
          sx={classes.linkBox}
          style={{
            paddingLeft:
              screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px',
            paddingRight:
              screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px',
            marginRight: getContactMarginRight()
          }}
          onMouseEnter={() => setContactHover(true)}
          onMouseLeave={() => setContactHover(false)}
        >
          <Box sx={classes.upperBox}>{t('menu.contact')}</Box>
          <Box
            sx={classes.underBox}
            style={{
              opacity: !contactHover ? 0 : 1
            }}
          />
        </Box>
      </Link>
    </>
  )
}
