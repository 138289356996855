/* eslint-disable import/prefer-default-export */
export type PageType =
  | 'services'
  | 'engineering'
  | 'software-development'
  | 'projects'
  | 'about'
  | 'contact'
  | 'privacy'
  | 'terms'
  | 'articles'
  | 'A0001'
  | 'A0002'
  | 'A0003'
  | 'applications'
  | 'scygate'
  | 'dathascy'
  | 'software'
  | 'products'
  | 'technology'

export function translateHref(
  currentLocale: string | string[],
  page: PageType
) {
  switch (page) {
    case 'services':
      return `/${currentLocale}/${currentLocale === 'de' ? 'dienstleistungen' : 'services'}/`
    case 'engineering':
      return `/${currentLocale}/${currentLocale === 'de' ? 'dienstleistungen/technische-dienstleistungen' : 'services/engineering'}/`
    case 'software-development':
      return `/${currentLocale}/${currentLocale === 'de' ? 'dienstleistungen/software-entwicklung' : 'services/software-development'}/`
    case 'projects':
      return `/${currentLocale}/${currentLocale === 'de' ? 'projekte' : 'projects'}/`
    case 'applications':
      return `/${currentLocale}/${currentLocale === 'de' ? 'anwendungen' : 'applications'}/`
    case 'software':
      return `/${currentLocale}/${currentLocale === 'de' ? 'software' : 'software'}/`
    case 'dathascy':
      return `/${currentLocale}/${currentLocale === 'de' ? 'software/dathascy' : 'software/dathascy'}/`
    case 'products':
      return `/${currentLocale}/${currentLocale === 'de' ? 'products' : 'products'}/`
    case 'scygate':
      return `/${currentLocale}/${currentLocale === 'de' ? 'products/smart_sensors/scygate' : 'products/smart_sensors/scygate'}/`
    case 'about':
      return `/${currentLocale}/${currentLocale === 'de' ? 'unternehmen' : 'about'}/`
    case 'contact':
      return `/${currentLocale}/${currentLocale === 'de' ? 'kontakt' : 'contact'}/`
    case 'privacy':
      return `/${currentLocale}/${currentLocale === 'de' ? 'datenschutz' : 'privacy'}/`
    case 'terms':
      return `/${currentLocale}/${currentLocale === 'de' ? 'terms' : 'terms'}/`
    case 'articles':
      return `/${currentLocale}/${currentLocale === 'de' ? 'artikel' : 'articles'}/`
    case 'A0001':
      return `/${currentLocale}/${currentLocale === 'de' ? 'artikel/bedeutung-der-maschinendatenerfassung' : 'articles/importance-of-machine-data-collection'}/`
    case 'A0002':
      return `/${currentLocale}/${currentLocale === 'de' ? 'artikel/herausforderungen-bei-der-maschinendatenerfassung' : 'articles/challenges-in-machine-data-collection'}/`
    case 'A0003':
      return `/${currentLocale}/${currentLocale === 'de' ? 'artikel/mehrwert-einer-guten-datenueberwachungsschnittstelle' : 'articles/added-value-of-a-good-data-monitoring-interface'}/`
    case 'technology':
      return `/${currentLocale}/${currentLocale === 'de' ? 'technologie' : 'technology'}/`
  }
}
