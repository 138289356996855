// React, Next
import { useContext } from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'

// Mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// Custom
import SocialMediaIconButton from 'components/tools/buttons/socialMedia/SocialMediaIconButton'
import Text from 'components/tools/text/Text'
import EmailButton from 'components/tools/buttons/email/EmailButton'
import { GlobalContext } from 'context/GlobalContextProvider'
import { translateHref } from 'assets/functions/links'

// Config
import i18nextConfig from 'next-i18next.config'

// Style
import themeColors from 'assets/theme/colors'
import breakpoints from '@/assets/theme/breakpoints'
import classes from './MainFooter-CSS'

export default function MainFooter() {
  const { screenDimensions, links } = useContext(GlobalContext)
  const { t } = useTranslation('common')

  const router = useRouter()
  const currentLocale = router.query.locale || i18nextConfig.i18n.defaultLocale

  const getGrid = () => {
    if (screenDimensions.width >= breakpoints.mobileLarge) return 'span 4'
    return 'span 12'
  }

  const getContent = (target: 'medias' | 'contact' | 'links') => {
    if (target === 'medias') {
      return (
        <Box
          sx={classes.col}
          gridColumn={getGrid()}
          style={{
            borderBottom:
              screenDimensions.width < breakpoints.desktopMid
                ? '1px solid #eee'
                : 'none'
          }}
        >
          <div>
            <Text
              text={t('footer.followUs')}
              noSelect
              marginBottom={10}
              justifyContent="center"
              display="flex"
              fontSize="0.875rem"
              color={themeColors.gray.mid}
            />
            <SocialMediaIconButton
              socialMedia="linkedin"
              href={links.linkedin}
              color={themeColors.gray.mid}
              scale={1.2}
              tooltip="LinkedIn"
            />
          </div>
        </Box>
      )
    }
    if (target === 'contact') {
      return (
        <Box
          sx={classes.col}
          gridColumn={getGrid()}
          style={{
            borderBottom:
              screenDimensions.width < breakpoints.desktopMid
                ? '1px solid #eee'
                : 'none'
          }}
        >
          <Typography
            align="center"
            fontSize="0.875rem"
            color={themeColors.gray.lightest}
          >
            <>
              <EmailButton
                label="info@kroemtech.com"
                mailto="mailto:info@kroemtech.com"
                color={themeColors.gray.lightest}
                noLinkEffects
                useHoverEffect
              />
              <br />
              +41 61 717 82 45
            </>
          </Typography>
        </Box>
      )
    }
    return (
      <Box
        sx={classes.col}
        gridColumn={getGrid()}
        style={{
          borderBottom:
            screenDimensions.width < breakpoints.desktopMid
              ? '1px solid #eee'
              : undefined
        }}
      >
        <Box>
          <Box sx={classes.linkBox}>
            <Link
              href={translateHref(currentLocale, 'about')}
              style={classes.typoLink}
            >
              <Text
                text={t('footer.about')}
                fontWeight={400}
                fontSize="0.875rem"
                color={themeColors.gray.lightest}
              />
            </Link>
          </Box>

          <Box sx={classes.linkBox}>
            <Link
              href={translateHref(currentLocale, 'articles')}
              style={classes.typoLink}
            >
              <Text
                text={t('footer.articles')}
                fontWeight={400}
                fontSize="0.875rem"
                color={themeColors.gray.lightest}
              />
            </Link>
          </Box>
          <Box sx={classes.linkBox}>
            <Link
              href={translateHref(currentLocale, 'privacy')}
              style={classes.typoLink}
            >
              <Text
                text={t('footer.privacy')}
                fontWeight={400}
                fontSize="0.875rem"
                color={themeColors.gray.lightest}
              />
            </Link>
          </Box>

          {/*
            <Box sx = {classes.linkBox}>
              <Link
                href  = {`/${currentLocale}/terms`}
                style = {classes.typoLink}
              >
                <Text
                  text       = {t('footer.terms')}
                  fontWeight = {400}
                  fontSize   = "0.875rem"
                  color      = {themeColors.gray.mid}
                />
              </Link>
            </Box>
            */}
        </Box>
      </Box>
    )
  }

  const getContentSeq = () => {
    if (screenDimensions.width >= breakpoints.desktopMid) {
      return (
        <>
          {getContent('contact')}
          {/* getContent('medias') */}
          {getContent('links')}
        </>
      )
    }
    if (screenDimensions.width >= breakpoints.mobileLarge) {
      return (
        <>
          {getContent('links')}
          {getContent('contact')}
          {/* getContent('medias') */}
        </>
      )
    }
    return (
      <>
        {getContent('contact')}
        {/* getContent('medias') */}
        {getContent('links')}
      </>
    )
  }

  return (
    <Box sx={classes.mainBox}>
      <Box sx={classes.upperBox} gridTemplateColumns="repeat(12, 1fr)">
        <Box
          sx={classes.col}
          gridColumn={getGrid()}
          style={{
            borderBottom:
              screenDimensions.width < breakpoints.desktopMid
                ? '1px solid #eee'
                : undefined
          }}
        >
          <Typography
            align="center"
            fontSize="0.875rem"
            color={themeColors.gray.lightest}
          >
            <b>Kroemtech GmbH</b>
            <br />
            Christoph Merian-Ring 11
            <br />
            CH-4153 Reinach
            <br />
            {t('footer.ch')}
          </Typography>
        </Box>

        {getContentSeq()}
      </Box>
      <Box sx={classes.lowerBox}>
        <Text
          text="© Kroemtech GmbH"
          noSelect
          color={themeColors.gray.lightest}
        />
      </Box>
    </Box>
  )
}
