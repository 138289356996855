const classes = {
  section: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gap: '16px',
    justifyContent: 'center'
  },
  section1: {
    display: 'grid',
    gap: '16px'
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  iconBox: {
    cursor: 'pointer',
    color: '#555'
  }
}

export default classes
