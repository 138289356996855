// Icons
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety'
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf'
import SecurityIcon from '@mui/icons-material/Security'
import GavelIcon from '@mui/icons-material/Gavel'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import PestControlIcon from '@mui/icons-material/PestControl'
import TimerIcon from '@mui/icons-material/Timer'
import StoreIcon from '@mui/icons-material/Store'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import WaterIcon from '@mui/icons-material/Water'
import PolicyIcon from '@mui/icons-material/Policy'
import EngineeringIcon from '@mui/icons-material/Engineering'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import MoreTimeIcon from '@mui/icons-material/MoreTime'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import DeleteIcon from '@mui/icons-material/Delete'
import MoneyOffIcon from '@mui/icons-material/MoneyOff'
import MasksIcon from '@mui/icons-material/Masks'
import YardIcon from '@mui/icons-material/Yard'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import StarsIcon from '@mui/icons-material/Stars'
import InvertColorsIcon from '@mui/icons-material/InvertColors'

// Styles
import themeColors from '@/assets/theme/colors'

export const globalSectionProps = {
  motorHealth: {
    cardsNumberTitle: 'cards.motorHealth.title',
    cardsNumberCondition: 'cards.motorHealth.conditions',
    cardsNumberDescription: 'cards.motorHealth.description',
    image:
      'https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/applications/motors.webp',
    background: themeColors.kroemtech.background,
    alignedTexts: [
      {
        boldText: 'cards.motorHealth.benefits.bold01',
        text: 'cards.motorHealth.benefits.01',
        icon: <TrendingUpIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.motorHealth.benefits.bold02',
        text: 'cards.motorHealth.benefits.02',
        icon: <ThumbUpAltIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.motorHealth.benefits.bold03',
        text: 'cards.motorHealth.benefits.03',
        icon: <EngineeringIcon sx={{ color: themeColors.bg.start }} />
      }
    ]
  },
  airQuality: {
    cardsNumberTitle: 'cards.airQuality.title',
    cardsNumberCondition: 'cards.airQuality.conditions',
    cardsNumberDescription: 'cards.airQuality.description',
    image:
      'https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/applications/air_quality.webp',
    background: themeColors.kroemtech.background,
    alignedTexts: [
      {
        boldText: 'cards.airQuality.benefits.bold01',
        text: 'cards.airQuality.benefits.01',
        icon: <EngineeringIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.airQuality.benefits.bold02',
        text: 'cards.airQuality.benefits.02',
        icon: <EnergySavingsLeafIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.airQuality.benefits.bold03',
        text: 'cards.airQuality.benefits.03',
        icon: <TrendingUpIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.airQuality.benefits.bold04',
        text: 'cards.airQuality.benefits.04',
        icon: <ThumbUpAltIcon sx={{ color: themeColors.bg.start }} />
      }
    ]
  },
  goodsTracking: {
    cardsNumberTitle: 'cards.goodsTracking.title',
    cardsNumberCondition: 'cards.goodsTracking.conditions',
    cardsNumberDescription: 'cards.goodsTracking.description',
    image:
      'https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/applications/truck_with_location_icon.webp',
    background: themeColors.kroemtech.background,
    alignedTexts: [
      {
        boldText: 'cards.goodsTracking.benefits.bold01',
        text: 'cards.goodsTracking.benefits.01',
        icon: <TrendingUpIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.goodsTracking.benefits.bold02',
        text: 'cards.goodsTracking.benefits.02',
        icon: <ThumbUpAltIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.goodsTracking.benefits.bold03',
        text: 'cards.goodsTracking.benefits.03',
        icon: <SecurityIcon sx={{ color: themeColors.bg.start }} />
      }
    ]
  },
  waterQuality: {
    cardsNumberTitle: 'cards.waterQuality.title',
    cardsNumberCondition: 'cards.waterQuality.conditions',
    cardsNumberDescription: 'cards.waterQuality.description',
    image:
      'https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/applications/water_treatment_plant.webp',
    background: themeColors.kroemtech.background,
    alignedTexts: [
      {
        boldText: 'cards.waterQuality.benefits.bold01',
        text: 'cards.waterQuality.benefits.01',
        icon: <GavelIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.waterQuality.benefits.bold02',
        text: 'cards.waterQuality.benefits.02',
        icon: <ThumbUpAltIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.waterQuality.benefits.bold03',
        text: 'cards.waterQuality.benefits.03',
        icon: <HealthAndSafetyIcon sx={{ color: themeColors.bg.start }} />
      }
    ]
  },
  workerSafety: {
    cardsNumberTitle: 'cards.workerSafety.title',
    cardsNumberCondition: 'cards.workerSafety.conditions',
    cardsNumberDescription: 'cards.workerSafety.description',
    image:
      'https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/applications/contaminated_site.webp',
    background: themeColors.kroemtech.background,
    alignedTexts: [
      {
        boldText: 'cards.workerSafety.benefits.bold01',
        text: 'cards.workerSafety.benefits.01',
        icon: <TrendingUpIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.workerSafety.benefits.bold02',
        text: 'cards.workerSafety.benefits.02',
        icon: <EngineeringIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.workerSafety.benefits.bold03',
        text: 'cards.workerSafety.benefits.03',
        icon: <SettingsSuggestIcon sx={{ color: themeColors.bg.start }} />
      }
    ]
  },
  energyConsumption: {
    cardsNumberTitle: 'cards.energyConsumption.title',
    cardsNumberCondition: 'cards.energyConsumption.conditions',
    cardsNumberDescription: 'cards.energyConsumption.description',
    image:
      'https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/applications/energy_consumption.webp',
    background: themeColors.kroemtech.background,
    alignedTexts: [
      {
        boldText: 'cards.energyConsumption.benefits.bold01',
        text: 'cards.energyConsumption.benefits.01',
        icon: <AttachMoneyIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.energyConsumption.benefits.bold02',
        text: 'cards.energyConsumption.benefits.02',
        icon: <MoreTimeIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.energyConsumption.benefits.bold03',
        text: 'cards.energyConsumption.benefits.03',
        icon: <EventAvailableIcon sx={{ color: themeColors.bg.start }} />
      }
    ]
  },
  qualityControl: {
    cardsNumberTitle: 'cards.qualityControl.title',
    cardsNumberCondition: 'cards.qualityControl.conditions',
    cardsNumberDescription: 'cards.qualityControl.description',
    image:
      'https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/applications/apple_pool.webp',
    background: themeColors.kroemtech.background,
    alignedTexts: [
      {
        boldText: 'cards.qualityControl.benefits.bold01',
        text: 'cards.qualityControl.benefits.01',
        icon: <TrendingUpIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.qualityControl.benefits.bold02',
        text: 'cards.qualityControl.benefits.02',
        icon: <ThumbUpAltIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.qualityControl.benefits.bold03',
        text: 'cards.qualityControl.benefits.03',
        icon: <SettingsSuggestIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.qualityControl.benefits.bold04',
        text: 'cards.qualityControl.benefits.04',
        icon: (
          <PrecisionManufacturingIcon sx={{ color: themeColors.bg.start }} />
        )
      },
      {
        boldText: 'cards.qualityControl.benefits.bold05',
        text: 'cards.qualityControl.benefits.05',
        icon: <DocumentScannerIcon sx={{ color: themeColors.bg.start }} />
      }
    ]
  },
  ripeningControl: {
    cardsNumberTitle: 'cards.ripeningControl.title',
    cardsNumberCondition: 'cards.ripeningControl.conditions',
    cardsNumberDescription: 'cards.ripeningControl.description',
    image:
      'https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/applications/fruit_storage.webp',
    background: themeColors.kroemtech.background,
    alignedTexts: [
      {
        boldText: 'cards.ripeningControl.benefits.bold01',
        text: 'cards.ripeningControl.benefits.01',
        icon: <TimerIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.ripeningControl.benefits.bold02',
        text: 'cards.ripeningControl.benefits.02',
        icon: <StoreIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.ripeningControl.benefits.bold03',
        text: 'cards.ripeningControl.benefits.03',
        icon: <DeleteIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.ripeningControl.benefits.bold04',
        text: 'cards.ripeningControl.benefits.04',
        icon: <NotificationsActiveIcon sx={{ color: themeColors.bg.start }} />
      }
    ]
  },
  leakDetection: {
    cardsNumberTitle: 'cards.leakDetection.title',
    cardsNumberCondition: 'cards.leakDetection.conditions',
    cardsNumberDescription: 'cards.leakDetection.description',
    image:
      'https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/applications/gas_leak.webp',
    background: themeColors.kroemtech.background,
    alignedTexts: [
      {
        boldText: 'cards.leakDetection.benefits.bold01',
        text: 'cards.leakDetection.benefits.01',
        icon: <MoneyOffIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.leakDetection.benefits.bold02',
        text: 'cards.leakDetection.benefits.02',
        icon: <EngineeringIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.leakDetection.benefits.bold03',
        text: 'cards.leakDetection.benefits.03',
        icon: <MasksIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.leakDetection.benefits.bold04',
        text: 'cards.leakDetection.benefits.04',
        icon: <GavelIcon sx={{ color: themeColors.bg.start }} />
      }
    ]
  },
  soil: {
    cardsNumberTitle: 'cards.soil.title',
    cardsNumberCondition: 'cards.soil.conditions',
    cardsNumberDescription: 'cards.soil.description',
    image:
      'https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/applications/soil.webp',
    background: themeColors.kroemtech.background,
    alignedTexts: [
      {
        boldText: 'cards.soil.benefits.bold01',
        text: 'cards.soil.benefits.01',
        icon: <YardIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.soil.benefits.bold02',
        text: 'cards.soil.benefits.02',
        icon: <QueryStatsIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.soil.benefits.bold03',
        text: 'cards.soil.benefits.03',
        icon: <PestControlIcon sx={{ color: themeColors.bg.start }} />
      }
    ]
  },
  solarRadiation: {
    cardsNumberTitle: 'cards.solarRadiation.title',
    cardsNumberCondition: 'cards.solarRadiation.conditions',
    cardsNumberDescription: 'cards.solarRadiation.description',
    image:
      'https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/applications/sunlight.webp',
    background: themeColors.kroemtech.background,
    alignedTexts: [
      {
        boldText: 'cards.solarRadiation.benefits.bold01',
        text: 'cards.solarRadiation.benefits.01',
        icon: <QueryStatsIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.solarRadiation.benefits.bold02',
        text: 'cards.solarRadiation.benefits.02',
        icon: <EnergySavingsLeafIcon sx={{ color: themeColors.bg.start }} />
      }
    ]
  },
  spoiledFood: {
    cardsNumberTitle: 'cards.spoiledFood.title',
    cardsNumberCondition: 'cards.spoiledFood.conditions',
    cardsNumberDescription: 'cards.spoiledFood.description',
    image:
      'https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/applications/putrid_orange.webp',
    background: themeColors.kroemtech.background,
    alignedTexts: [
      {
        boldText: 'cards.spoiledFood.benefits.bold01',
        text: 'cards.spoiledFood.benefits.01',
        icon: <MoneyOffIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.spoiledFood.benefits.bold02',
        text: 'cards.spoiledFood.benefits.02',
        icon: <GavelIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.spoiledFood.benefits.bold03',
        text: 'cards.spoiledFood.benefits.03',
        icon: <StarsIcon sx={{ color: themeColors.bg.start }} />
      }
    ]
  },
  naturalCondition: {
    cardsNumberTitle: 'cards.naturalCondition.title',
    cardsNumberCondition: 'cards.naturalCondition.conditions',
    cardsNumberDescription: 'cards.naturalCondition.description',
    image:
      'https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/applications/river_level_meter.webp',
    background: themeColors.kroemtech.background,
    alignedTexts: [
      {
        boldText: 'cards.naturalCondition.benefits.bold01',
        text: 'cards.naturalCondition.benefits.01',
        icon: <NotificationsActiveIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.naturalCondition.benefits.bold02',
        text: 'cards.naturalCondition.benefits.02',
        icon: <PolicyIcon sx={{ color: themeColors.bg.start }} />
      }
    ]
  },
  undergroundWater: {
    cardsNumberTitle: 'cards.undergroundWater.title',
    cardsNumberCondition: 'cards.undergroundWater.conditions',
    cardsNumberDescription: 'cards.undergroundWater.description',
    image:
      'https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/applications/underground_water.webp',
    background: themeColors.kroemtech.background,
    alignedTexts: [
      {
        boldText: 'cards.undergroundWater.benefits.bold01',
        text: 'cards.undergroundWater.benefits.01',
        icon: <WaterIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.undergroundWater.benefits.bold02',
        text: 'cards.undergroundWater.benefits.02',
        icon: <InvertColorsIcon sx={{ color: themeColors.bg.start }} />
      },
      {
        boldText: 'cards.undergroundWater.benefits.bold03',
        text: 'cards.undergroundWater.benefits.03',
        icon: <HealthAndSafetyIcon sx={{ color: themeColors.bg.start }} />
      }
    ]
  }
}
