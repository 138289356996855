const classes = {
  mainBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  navLink: {
    marginLeft: 3,
    marginRight: 3
  },
  iconClass: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8
    }
  }
}

export default classes
