// React, Next
import { ReactElement, useEffect, useRef, useState, RefObject } from 'react'
import { useTranslation } from 'next-i18next'

// Mui
import Box from '@mui/material/Box'
import { motion } from 'framer-motion'

// Types
import { SectionKey } from '@/types/global_types'

// Sections
import GetStartedSection from '@/components/getStarted/GetStartedSection'
import WelcomeSection from './sections/WelcomeSection'
import RealTimeSection from './sections/RealTimeSection'
import OurServicesSection from './sections/OurServicesSection'
import DataCollectionSection from './sections/DataCollectionSection'
import WarehousingSection from './sections/WarehousingSection'
import IntegratonSystemsSection from './sections/IntegratonSystemsSection'
import AnalyticsSection from './sections/AnalyticsSection'

// Styles
import classes from './ServicesView-CSS'

export default function ServicesView() {
  const { t } = useTranslation('services')

  const sectionRefs: Record<SectionKey, RefObject<HTMLDivElement>> = {
    ourServices: useRef<HTMLDivElement>(null),
    realTime: useRef<HTMLDivElement>(null),
    analytics: useRef<HTMLDivElement>(null),
    integratonSystems: useRef<HTMLDivElement>(null),
    warehousing: useRef<HTMLDivElement>(null),
    dataCollection: useRef<HTMLDivElement>(null),
    getStarted: useRef<HTMLDivElement>(null)
  }

  const initialVisibility: Record<SectionKey, boolean> = {
    ourServices: false,
    realTime: false,
    analytics: false,
    integratonSystems: false,
    warehousing: false,
    dataCollection: false,
    getStarted: false
  }

  const [visibility, setVisibility] = useState(initialVisibility)

  const updateVisibility = (key: string, isVisible: boolean) => {
    setVisibility(prev => ({ ...prev, [key]: isVisible }))
  }

  useEffect(() => {
    const observers = (Object.keys(sectionRefs) as SectionKey[]).map(key => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => updateVisibility(key, entry.isIntersecting))
      })
      if (sectionRefs[key].current) {
        observer.observe(sectionRefs[key].current as Element)
      }
      return observer
    })

    return () => {
      observers.forEach(observer => observer.disconnect())
    }
  }, [])

  const getSection = (p: {
    section: ReactElement
    reference: RefObject<HTMLDivElement>
    visible: boolean
  }) => (
    <motion.div
      ref={p.reference}
      initial={{ opacity: 0, y: 20 }}
      animate={p.visible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ delay: 0.07, duration: 0.75 }}
    >
      {p.section}
    </motion.div>
  )

  const scrollToSection = (section: SectionKey) => {
    sectionRefs[section]?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div>
      <Box style={classes.main}>
        <WelcomeSection />

        {getSection({
          section: <OurServicesSection scrollToSection={scrollToSection} />,
          reference: sectionRefs.ourServices,
          visible: visibility.ourServices
        })}

        {getSection({
          section: <RealTimeSection />,
          reference: sectionRefs.realTime,
          visible: visibility.realTime
        })}

        {getSection({
          section: <DataCollectionSection />,
          reference: sectionRefs.dataCollection,
          visible: visibility.dataCollection
        })}

        {getSection({
          section: <WarehousingSection />,
          reference: sectionRefs.warehousing,
          visible: visibility.warehousing
        })}

        {getSection({
          section: <AnalyticsSection />,
          reference: sectionRefs.analytics,
          visible: visibility.analytics
        })}

        {getSection({
          section: <IntegratonSystemsSection />,
          reference: sectionRefs.integratonSystems,
          visible: visibility.integratonSystems
        })}

        {getSection({
          section: (
            <GetStartedSection
              title={t('started.title')}
              subTitle={t('started.subTitle')}
              button={t('started.button')}
            />
          ),
          reference: sectionRefs.getStarted,
          visible: visibility.getStarted
        })}
      </Box>
    </div>
  )
}
