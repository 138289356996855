// React, Next
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useContext, useEffect, useRef, useState } from 'react'
import { StaticImport } from 'next/dist/shared/lib/get-img-props'
import { useRouter } from 'next/router'
import Link from 'next/link'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'
import { translateHref } from 'assets/functions/links'

// Img
import Data from 'public/img/index/server_w750.webp'
import RealTime from 'public/img/index/worker_holding_tablet_w700.webp'
import Custom from 'public/img/index/sensor_pcb_w700.webp'
import Interative from 'public/img/index/hands_holding_tablet_w700.webp'

// Config
import i18nextConfig from 'next-i18next.config'

// Style
import themeColors from 'assets/theme/colors'
import breakpoints from 'assets/theme/breakpoints'
import classes from './ServicesSection-CSS'

export default function ServicesSection() {
  const { t } = useTranslation('index')
  const { screenDimensions } = useContext(GlobalContext)

  const router = useRouter()
  const currentLocale = router.query.locale || i18nextConfig.i18n.defaultLocale

  const ref1: any = useRef()
  const ref2: any = useRef()
  const ref3: any = useRef()
  const ref4: any = useRef()

  const getFontH3 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '3rem'
    if (w > breakpoints.desktopSmall) return '2rem'
    if (w > breakpoints.desktopToMobile) return '1.725rem'
    if (w > breakpoints.mobileSmall) return '1.6rem'
    return '1.25rem'
  }

  const getFontH4 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '1.25rem'
    if (w > breakpoints.mobileSmall) return '1.125rem'
    return '1rem'
  }

  const getFontB1 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.mobileSmall) return '1rem'
    return '0.875rem'
  }

  const getPadding = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return `${screenDimensions.width / 16}px`
    if (w > breakpoints.desktopSmall) return `${screenDimensions.width / 20}px`
    if (w > breakpoints.desktopToMobile)
      return `${screenDimensions.width / 30}px`
    return '0px'
  }

  const getGrid4 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return 'span 3'
    if (w > breakpoints.mobileMid) return 'span 6'
    return 'span 12'
  }

  const getCardWidth = () => {
    const w = screenDimensions.width
    if (getGrid4() === 'span 12') {
      return {
        max: w - 40,
        set: w - 40,
        min: w - 40
      }
    }
    return { max: 600, set: undefined, min: 300 }
  }

  const getSectionMarginTop = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '10rem'
    if (w > breakpoints.desktopMid) return '8rem'
    if (w > breakpoints.desktopSmall) return '7rem'
    if (w > breakpoints.desktopToMobile) return '6rem'
    return '3rem'
  }

  const getCardTextHeight = (self: number) => {
    try {
      if (getGrid4() === 'span 12') {
        return 'auto'
      }
      const h = [
        ref1.current.getBoundingClientRect().height,
        ref2.current.getBoundingClientRect().height,
        ref3.current.getBoundingClientRect().height,
        ref4.current.getBoundingClientRect().height
      ] as number[]
      return Math.max(...h) + 15
    } catch {
      return 190
    }
  }

  const getImageHeight = () => {
    try {
      return ref1.current.getBoundingClientRect().width / 2.5
    } catch {
      return 150
    }
  }

  // *** Section ***
  const getCard = (p: {
    ndx: number
    ref: any
    title: string
    text: string
    barColor: string
    img: string | StaticImport
    alt: string
    href: string
    objectPosition?: string
  }) => (
    <Box
      gridColumn={getGrid4()}
      sx={{
        '&:hover': {
          cursor: 'default',
          transform: 'scale(1.03)',
          transition: 'transform 200ms'
        },
        maxWidth: getCardWidth().max,
        minWidth: getCardWidth().min,
        width: getCardWidth().set
      }}
      style={classes.card}
    >
      <Box sx={classes.coloredBar} />
      <Box>
        <Box
          sx={classes.cardContentBox1}
          style={{
            height: getCardTextHeight(p.ndx)
          }}
        >
          <Box ref={p.ref} style={classes.cardContentBox2}>
            <Box style={classes.cardDiv}>
              <Image
                alt={p.alt}
                src={p.img}
                style={{
                  width: '93%',
                  height: getImageHeight(),
                  objectFit: 'cover',
                  objectPosition: p.objectPosition
                }}
                priority={false}
              />
            </Box>
            <Typography
              variant="body1"
              fontSize={getFontH4()}
              style={classes.typoH4}
            >
              {p.title}
            </Typography>
            <Typography
              variant="body1"
              fontSize={getFontB1()}
              style={classes.typoB1}
            >
              {p.text}
            </Typography>
          </Box>
        </Box>
        <Link href={p.href}>
          <Box style={classes.learnMoreBox}>
            <Typography
              variant="body1"
              fontSize="0.875rem"
              style={classes.learnMoreTypo}
            >
              {t('learnMore')} &#x279C;
            </Typography>
          </Box>
        </Link>
      </Box>
    </Box>
  )

  const getSection = () => (
    <>
      <Box
        style={{
          zIndex: 2
        }}
      >
        <Typography variant="h3" fontSize={getFontH3()} sx={classes.typoTitle}>
          {t('services.title')}
        </Typography>
      </Box>
      <div style={classes.cardDiv}>
        <Box
          gridTemplateColumns="repeat(12, 1fr)"
          columnGap={3}
          rowGap={3}
          sx={classes.cardBox}
          style={{
            paddingLeft: getPadding(),
            paddingRight: getPadding()
          }}
        >
          {getCard({
            ndx: 1,
            ref: ref1,
            title: t('services.card1.title'),
            text: t('services.card1.content'),
            barColor: themeColors.kroemtech.green,
            img: RealTime,
            alt: 'machine development',
            href: translateHref(currentLocale, 'services')
          })}
          {getCard({
            ndx: 2,
            ref: ref2,
            title: t('services.card2.title'),
            text: t('services.card2.content'),
            barColor: themeColors.kroemtech.greenBlue,
            img: Custom,
            alt: 'data collection',
            href: translateHref(currentLocale, 'services'),
            objectPosition: '50% 60%'
          })}
          {getCard({
            ndx: 3,
            ref: ref3,
            title: t('services.card3.title'),
            text: t('services.card3.content'),
            barColor: themeColors.kroemtech.blueGreen,
            img: Data,
            alt: 'control system',
            href: translateHref(currentLocale, 'services')
          })}
          {getCard({
            ndx: 4,
            ref: ref4,
            title: t('services.card4.title'),
            text: t('services.card4.content'),
            barColor: themeColors.kroemtech.blue,
            img: Interative,
            alt: 'web app',
            href: translateHref(currentLocale, 'services')
          })}
        </Box>
      </div>
    </>
  )

  // *** MAIN RENDER ***
  return (
    <Box
      component="section"
      style={{
        ...classes.section,
        marginTop: getSectionMarginTop()
      }}
    >
      {getSection()}
    </Box>
  )
}
