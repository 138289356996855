// React, Next
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import Link from 'next/link'

// Mui
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

// Custom
import { translateHref } from 'assets/functions/links'

// import SocialMediaIconButton from 'components/tools/buttons/socialMedia/SocialMediaIconButton'

// Config
import nextI18nextConfig from 'next-i18next.config'

// Style
import LanguageSwitcher2 from '../tools/buttons/LanguageSwitcher/LanguageSwitcher2'
import componentStyles from './MenuSideBar-CSS'

const useStyles = makeStyles(componentStyles)

type Props = {
  setDrawerOpen: (state: boolean) => void
}

export default function MenuSideBar(props: Props) {
  const classes = useStyles()

  // const { screenDimensions, links } = useContext(GlobalContext)
  const { t } = useTranslation('common')
  const router = useRouter()
  const currentLocale =
    router.query.locale || nextI18nextConfig.i18n.defaultLocale

  const linkList = [
    // { title: t('menu.engineering'), link: translateHref(currentLocale, 'engineering') },
    // { title: t('menu.softwareDev'), link: translateHref(currentLocale, 'software-development') },
    // { title: t('menu.projects'), link: translateHref(currentLocale, 'projects') },
    // { title: t('menu.scygate'), link: translateHref(currentLocale, 'scygate') },
    // { title: t('menu.dathascy'), link: translateHref(currentLocale, 'dathascy') },
    {
      title: t('menu.technology'),
      link: translateHref(currentLocale, 'technology')
    },
    {
      title: t('menu.services'),
      link: translateHref(currentLocale, 'services')
    },
    {
      title: t('menu.applications'),
      link: translateHref(currentLocale, 'applications')
    },
    { title: t('menu.contact'), link: translateHref(currentLocale, 'contact') }
  ]

  const renderLinks = (list: { title: string; link: string }[]) =>
    list.map(item => (
      <Link
        key={item.link}
        href={item.link}
        style={{ textDecoration: 'none' }}
        className={classes.linkStyle2}
      >
        <ListItem className={classes.linkStyle}>
          <ListItemText primary={item.title} />
        </ListItem>
      </Link>
    ))

  const getLangButtons = (mobileMode: boolean) => (
    <Box>
      <LanguageSwitcher2 currentLocale={currentLocale} />
    </Box>
  )

  return (
    <Box
      className={classes.mainBox}
      role="presentation"
      onClick={() => props.setDrawerOpen(false)}
      onKeyDown={() => props.setDrawerOpen(false)}
    >
      <ArrowForwardIosIcon
        onClick={() => props.setDrawerOpen(false)}
        className={classes.menuIcon}
      />

      <List>{renderLinks(linkList)}</List>

      {/* <Box className = {classes.mediasBox}>
        <SocialMediaIconButton
          socialMedia = "linkedin"
          href = {links.linkedin}
          color = {themeColors.gray.darker}
          scale = {1.2}
        />
      </Box> */}
      {getLangButtons(true)}
    </Box>
  )
}
