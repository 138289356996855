const classes = {
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
    marginBottom: '5px'
  },
  typoHeader: {
    fontSize: 16,
    fontWeight: 600
  },
  typo: {
    fontSize: 14
  },
  typoButton: {
    fontSize: 14,
    fontWeight: 800
  },
  formBox: {
    marginTop: '10px',
    marginBottom: '30px'
  },
  divider: {
    height: 0,
    marginTop: '-2px',
    overflow: 'hidden' as const,
    borderTop: '1px solid #e9ecef'
  }
}

export default classes
