import themeColors from '@/assets/theme/colors'

const classes = {
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    background: themeColors.gray.lightest,
    alignItems: 'center'
  },
  subsectionMain: {
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'left'
  },
  subsection: {
    width: '100%',
    background: '#ffffffc7',
    boxShadow: '#ffffffff 7px 20px 30px 8px;',
    padding: '2rem',
    borderRadius: '4px',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'left'
  },
  titleBox: {
    background: themeColors.gray.lightest,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '1.5rem 1rem 1.5rem 1rem'
  },
  subsectionDiv: {
    zIndex: 2
  },
  linkDiv: {
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'center'
  },
  linkButton: {
    minWidth: 150,
    background: themeColors.kroemtech.blue,
    zIndex: 2
  },
  imgBox: {
    width: '100%',
    height: '100%'
  }
}

export default classes
