// React, Next
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useContext, useEffect, useRef, useState } from 'react'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import VerifiedSharpIcon from '@mui/icons-material/VerifiedSharp'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'
import AlignedText from '@/components/alignedText/AlignedText'

// Img
import Benefits from 'public/img/index/warehouse_manager_with_tablet.webp'

// Style
import themeColors from 'assets/theme/colors'
import breakpoints from 'assets/theme/breakpoints'
import classes from './BenefitsSection-CSS'

export default function BenefitsSection() {
  const { t } = useTranslation('index')

  const { screenDimensions } = useContext(GlobalContext)

  const [mobileMode, setMobileMode] = useState(false)

  const sectionRef: any = useRef()

  useEffect(() => {
    setMobileMode(screenDimensions.width <= breakpoints.desktopToMobile)
  }, [screenDimensions])

  const getFontH1 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '2.3rem'
    if (w > breakpoints.desktopSmall) return '2rem'
    if (w > breakpoints.mobileMid) return '1.5rem'
    if (w > breakpoints.mobileSmall) return '1.3rem'
    return '1.1rem'
  }

  const getPaddingRight = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return `${screenDimensions.width / 18}px`
    if (w > breakpoints.desktopSmall) return `${screenDimensions.width / 28}px`
    if (w > breakpoints.desktopToMobile)
      return `${screenDimensions.width / 36}px`
    return '0px'
  }

  const getGrid = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopToMobile) return 'span 6'
    return 'span 12'
  }

  const getImgSize = () => {
    let sectionH = 0

    try {
      sectionH = sectionRef.current.clientHeight
    } catch {
      /* empty */
    }

    if (!mobileMode) {
      return {
        width: sectionH + 200,
        height: sectionH + 200
      }
    }

    const w = screenDimensions.width * 0.8
    return {
      width: w,
      height: w
    }
  }

  const getSectionMarginTop = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '8rem'
    if (w > breakpoints.desktopMid) return '6rem'
    if (w > breakpoints.desktopSmall) return '6rem'
    if (w > breakpoints.desktopToMobile) return '5rem'
    if (w > breakpoints.mobileMid) return '16rem'
    if (w > breakpoints.mobileSmall) return '10rem'
    return '8rem'
  }

  // *** SECTION ***

  const getBullet = (text: string) => (
    <AlignedText
      icon={
        <VerifiedSharpIcon
          style={classes.icon}
          sx={{
            color: themeColors.kroemtech.lightBlue
          }}
        />
      }
      boldText={text}
      textColor={themeColors.gray.lightest}
    />
  )

  const getSection = () => (
    <>
      <Box
        gridColumn={getGrid()}
        sx={classes.subsection}
        style={{
          justifyContent: !mobileMode ? 'left' : 'center',
          paddingLeft: !mobileMode ? getPaddingRight() : '0px'
        }}
      >
        <Image
          alt="Kroemtech"
          src={Benefits}
          style={{
            width: getImgSize().width,
            height: getImgSize().height,
            objectPosition: '0% 50%',
            objectFit: 'contain',
            position: 'absolute'
          }}
          priority={false}
        />
      </Box>
      <Box
        gridColumn={getGrid()}
        sx={classes.subsection}
        style={{
          paddingRight: getPaddingRight(),
          justifyContent: !mobileMode ? 'left' : 'center',
          paddingTop: mobileMode ? '9rem' : '0.5rem',
          paddingBottom: '0.5rem',
          paddingLeft: !mobileMode ? '1.5rem' : 0
        }}
      >
        <div style={classes.subsectionDiv}>
          <Typography
            variant="h1"
            fontSize={getFontH1()}
            style={{
              marginBottom: '1.875rem',
              textAlign: !mobileMode ? 'left' : 'center',
              color: themeColors.kroemtech.lightBlue,
              fontWeight: 'bold',
              borderBottom: `2px solid ${themeColors.kroemtech.lightBlue}`
            }}
          >
            {t('benefits.title')}
          </Typography>

          {getBullet(t('benefits.subtitle1'))}
          {getBullet(t('benefits.subtitle2'))}
          {getBullet(t('benefits.subtitle3'))}
          {getBullet(t('benefits.subtitle4'))}
          {getBullet(t('benefits.subtitle5'))}
          {getBullet(t('benefits.subtitle6'))}
          {getBullet(t('benefits.subtitle7'))}
        </div>
      </Box>
    </>
  )

  // *** MAIN RENDER ***
  return (
    <Box
      ref={sectionRef}
      component="section"
      gridTemplateColumns="repeat(12, 1fr)"
      style={{
        ...classes.section,
        marginTop: getSectionMarginTop()
      }}
    >
      {getSection()}
    </Box>
  )
}
