import themeColors from '@/assets/theme/colors'

const classes = {
  submitButton: {
    marginTop: '1rem',
    background: themeColors.bg.start,
    color: '#fff',
    minWidth: 120,
    fontSize: '1rem',
    '&:hover': {
      background: themeColors.bg.start,
      color: '#fff'
    }
  },
  submitButtonDisabled: {
    marginTop: '1rem',
    background: '#ccc',
    color: '#fff',
    minWidth: 120,
    fontSize: '1rem',
    '&:hover': {
      background: '#c5c5c5',
      color: '#fff'
    }
  }
}

export default classes
