import themeColors from 'assets/theme/colors'

const classes = {
  mainBox: {
    width: 250
  },
  socialMediaBox: {
    display: 'flex',
    marginLeft: '10px',
    marginTop: '10px'
  },
  menuIcon: {
    marginLeft: '15px',
    marginTop: '10px',
    marginBottom: '10px',
    color: themeColors.gray.dark,
    cursor: 'pointer',
    transform: 'scale(1.3)',
    '&:hover': {
      transform: 'scale(1.4)'
    }
  },
  linkStyle: {
    color: themeColors.gray.dark,
    '&:hover': {
      background: themeColors.info.lightest
    }
  },
  linkStyle2: {
    color: themeColors.gray.dark
  },
  mediasBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px'
  }
}

export default classes
