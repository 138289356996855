import themeColors from '@/assets/theme/colors'

const classes = {
  appbarStyle: {
    background: `linear-gradient(135deg, ${themeColors.bg.start} 0%, ${themeColors.bg.end} 100%)`
  },
  mainBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  linksBox: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    marginRight: '30px'
  },
  logoBox: {
    marginTop: '5px'
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center'
  },
  menuIcon: {
    cursor: 'pointer',
    transform: 'scale(1.5)',
    '&:hover': {
      transform: 'scale(1.6)'
    }
  },
  langButton: {
    fontSize: 11,
    fontWeight: 400,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7
    },
    '&:active': {
      opacity: 0.85
    }
  },
  langButton2: {
    fontSize: 11,
    fontWeight: 400,
    marginLeft: '5px',
    marginRight: '5px',
    height: '23px'
  },
  typoLink: {
    textDecoration: 'none',
    fontSize: 12,
    color: '#fff',
    '&:hover': {
      opacity: 0.7
    },
    '&:active': {
      opacity: 0.85
    }
  }
}

export default classes
