// React, Next
import { useTranslation } from 'next-i18next'
import { ReactElement, useContext, useEffect, useState } from 'react'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LooksOneIcon from '@mui/icons-material/LooksOne'
import LooksTwoIcon from '@mui/icons-material/LooksTwo'
import Looks3Icon from '@mui/icons-material/Looks3'
import Looks4Icon from '@mui/icons-material/Looks4'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'

// Style
import { getFontH1 } from '@/assets/theme/fonts'
import breakpoints from 'assets/theme/breakpoints'
import classes from './HowItWorksSection-CSS'

export default function HowItWorksSection() {
  const { t } = useTranslation('technology')
  const { screenDimensions } = useContext(GlobalContext)

  const [mobileMode, setMobileMode] = useState(false)

  useEffect(() => {
    setMobileMode(screenDimensions.width <= breakpoints.desktopToMobile)
  }, [screenDimensions])

  const getFontB1 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopToMobile) return '1.2rem'
    if (w > breakpoints.mobileLarge) return '1.1rem'
    if (w > breakpoints.mobileMid) return '1.1rem'
    return '1rem'
  }

  const getSectionPadding = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '12rem'
    if (w > breakpoints.desktopMid) return '8rem'
    if (w > breakpoints.desktopToMobile) return '3rem'
    return '1rem'
  }

  const getGrid4 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return 'span 3'
    if (w > breakpoints.desktopToMobile) return 'span 6'
    return 'span 12'
  }

  const getCardWidth = () => {
    const w = screenDimensions.width
    if (getGrid4() === 'span 12') {
      return {
        max: w - 40,
        set: w - 40,
        min: w - 40
      }
    }
    return { max: 600, set: undefined, min: 300 }
  }

  const getSectionMarginTop = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '6rem'
    if (w > breakpoints.desktopMid) return '4rem'
    if (w > breakpoints.desktopSmall) return '3rem'
    if (w > breakpoints.desktopToMobile) return '2rem'
    return '3rem'
  }

  // *** Section ***
  const getCard = (p: {
    title: string
    icon: ReactElement
    subTitle: string
  }) => (
    <Box
      gridColumn={getGrid4()}
      sx={{
        maxWidth: getCardWidth().max,
        minWidth: getCardWidth().min,
        width: getCardWidth().set,
        margin: !mobileMode ? '15px' : '10px 0 10px 0',
        display: 'flex',
        flexDirection: 'column'
      }}
      style={classes.card}
    >
      <Typography variant="body1" fontSize={getFontB1()} style={classes.typoB1}>
        {p.icon}
        {p.title}
      </Typography>
      <Typography variant="body1" fontSize={getFontB1()} sx={classes.typoB2}>
        {p.subTitle}
      </Typography>
    </Box>
  )

  const getSection = () => (
    <>
      <Box
        style={{
          zIndex: 2
        }}
      >
        <Typography
          variant="h3"
          sx={{
            ...classes.typoTitle,
            textAlign: 'left',
            fontWeight: '500',
            fontSize: getFontH1()
          }}
        >
          {t('HowItWorks.title')}
        </Typography>
      </Box>
      <Box gridTemplateColumns="repeat(12, 1fr)" sx={classes.cardBox}>
        {getCard({
          title: t('HowItWorks.card1.title'),
          icon: <LooksOneIcon sx={classes.numIcon} />,
          subTitle: t('HowItWorks.card1.content')
        })}
        {getCard({
          title: t('HowItWorks.card2.title'),
          icon: <LooksTwoIcon sx={classes.numIcon} />,
          subTitle: t('HowItWorks.card2.content')
        })}
        {getCard({
          title: t('HowItWorks.card3.title'),
          icon: <Looks3Icon sx={classes.numIcon} />,
          subTitle: t('HowItWorks.card3.content')
        })}
        {getCard({
          title: t('HowItWorks.card4.title'),
          icon: <Looks4Icon sx={classes.numIcon} />,
          subTitle: t('HowItWorks.card4.content')
        })}
      </Box>
    </>
  )

  // *** MAIN RENDER ***
  return (
    <Box
      component="section"
      style={{
        ...classes.section,
        marginTop: getSectionMarginTop(),
        paddingLeft: getSectionPadding(),
        paddingRight: getSectionPadding()
      }}
    >
      {getSection()}
    </Box>
  )
}
