// React
import React, { useState, ReactElement } from 'react'

// MUI
import Select from 'react-select'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import CheckIcon from '@mui/icons-material/Check'
import Zoom from '@mui/material/Zoom'
import Typography from '@mui/material/Typography'
import PersonIcon from '@mui/icons-material/Person'
import EmailIcon from '@mui/icons-material/Email'
import LockIcon from '@mui/icons-material/Lock'

// Custom
import { GetCountryPhonePrefixesWithFlags } from 'assets/img/flags/Countries'

type Props = {
  height?: undefined | number
  width?: undefined | number
  fontSize?: undefined | number
  valueContainerMarginTop?: undefined | number
  optionPadding?: undefined | number
  selectedOption: undefined | null | string
  icon?: 'person' | 'email' | 'check' | 'lock'
  validation?: undefined | null | number
  title?: undefined | string
  tooltip?: undefined | string | ReactElement
  iconColor?: undefined | string
  placeholder?: undefined | string
  disabled?: undefined | boolean
  onChange: (val: string) => void
}

export default function PhonePrefixDropdown(props: Props) {
  const customStyle = {
    control: (provided: any, state: any) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: props.height === undefined ? 30 : props.height,
      height: props.height === undefined ? 30 : props.height,
      width: '100%',
      marginLeft: '5px',
      fontSize: props.fontSize === undefined ? 12 : props.fontSize,
      boxShadow: state.isFocused ? null : null,
      border: 'none',
      minWidth: 90
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      height: props.height === undefined ? 30 : props.height,
      padding: '1 6px',
      marginTop: props.valueContainerMarginTop,
      fontSize: 14,
      fontWeight: 400
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      height: props.height === undefined ? 30 : props.height
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      borderBottom: '1px dotted #EEE',
      color: state.isSelected ? '#fff' : '#515252',
      padding: props.optionPadding === undefined ? 5 : props.optionPadding,
      fontSize: props.fontSize === undefined ? 12 : props.fontSize,
      fontFamily: state.data.fontFamily,
      fontWeight: 400
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      fontFamily: state.data.fontFamily
    })
  }

  const [options, setOptions] = useState(GetCountryPhonePrefixesWithFlags())

  const handleChange = (selectedOption: any) => {
    props.onChange(selectedOption.value)
  }

  const getSelectedOption = () => {
    for (let i = 0; i < options.length; i += 1) {
      if (props.selectedOption === options[i].value) return options[i]
    }
    return null
  }

  const [inputFocused, setInputFocused] = useState(false)

  const getIcon = () => {
    switch (props.icon) {
      case 'person':
        return <PersonIcon />
      case 'email':
        return <EmailIcon />
      case 'check':
        return <CheckIcon />
      case 'lock':
        return <LockIcon />
    }
  }

  const getBorder = () => {
    if (props.validation === 1) return '1px solid rgb(0,200,0)' // -1 = input rejected, 1 = validated, 0 = default(no input)
    if (props.validation === -1) return '1px solid rgb(200,0,0)'
    if (inputFocused) return '1px solid rgb(90,110,205)'
    return '1px solid rgb(255,255,255)'
  }

  return (
    <>
      {props.title === undefined ? null : (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            background: 'none',
            marginBottom: -5,
            marginLeft: 2
          }}
        >
          <Typography
            style={{
              fontWeight: 600,
              fontSize: 14
            }}
          >
            {props.title}
          </Typography>
        </Box>
      )}
      <Tooltip
        TransitionComponent={Zoom}
        arrow
        title={
          props.tooltip === undefined ? (
            ''
          ) : (
            <div style={{ fontSize: 14 }}>{props.tooltip}</div>
          )
        }
        placement="right"
        enterDelay={300}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            width: props.width === undefined ? '100%' : props.width,
            height: props.height === undefined ? 40 : props.height,
            background: '#fff',
            borderRadius: 5,
            border: getBorder(),
            transition: 'border ease-in 0.2s',
            marginTop: 10,
            marginBottom: 10
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
              width: props.width === undefined ? '100%' : props.width,
              height: props.height === undefined ? 40 : props.height,
              boxShadow: '0px 1px 2px 0px #00000022'
            }}
          >
            <Box
              style={{
                marginLeft: 10,
                color: props.iconColor === undefined ? '#666' : props.iconColor
              }}
            >
              {getIcon()}
            </Box>
            <Box
              style={{
                width: props.width === undefined ? '100%' : props.width,
                marginRight: 10
              }}
            >
              <Select
                placeholder={
                  props.placeholder === undefined
                    ? 'Select...'
                    : props.placeholder
                }
                styles={customStyle}
                value={getSelectedOption()}
                onChange={handleChange}
                options={options}
                isDisabled={
                  props.disabled === undefined ? false : props.disabled
                }
              />
            </Box>
          </Box>
        </Box>
      </Tooltip>
    </>
  )
}
