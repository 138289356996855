import themeColors from '@/assets/theme/colors'

const classes = {
  section: {
    display: 'grid',
    justifyContent: 'center',
    marginBottom: '3rem'
  },
  subsection: {
    display: 'flex',
    alignItems: 'center',
    background: themeColors.kroemtech.dunkelblau,
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 3px 0px'
  },
  subsectionDiv: {
    zIndex: 2,
    padding: '1.5rem',
    marginBottom: '1.5rem',
    marginTop: '1.5rem',
    background: `${themeColors.kroemtech.dunkelblau}bb`
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: '0.5rem',
    marginBottom: '-0.2rem'
  }
}

export default classes
