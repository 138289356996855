import { useContext, ReactElement } from 'react'

// Functions
import { useTranslation } from 'next-i18next'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'

// Styles
import breakpoints from '@/assets/theme/breakpoints'
import classes from './TermsView-CSS'
import themeColors from '@/assets/theme/colors'

export default function TermsView() {
  const { t } = useTranslation('terms')
  const { screenDimensions } = useContext(GlobalContext)

  const h3 = (text: string) => (
    <Typography
      variant="h3"
      sx={classes.h3}
      style={{
        fontSize:
          screenDimensions.width > breakpoints.mobileSmall
            ? '1.1rem'
            : '0.95rem'
      }}
    >
      {text}
    </Typography>
  )

  const h2 = (text: string) => (
    <Typography
      variant="h2"
      sx={classes.h2}
      style={{
        fontSize:
          screenDimensions.width > breakpoints.mobileSmall ? '1.3rem' : '1.1rem'
      }}
    >
      {text}
    </Typography>
  )

  const link = (text: string, href: string) => (
    <a
      href={href}
      target="_blank"
      style={{
        color: themeColors.info.main,
        fontWeight: 'bold'
      }}
    >
      {text}
    </a>
  )

  const b2 = (text: string | ReactElement) => (
    <Typography
      variant="body1"
      sx={classes.b2}
      style={{
        fontSize:
          screenDimensions.width > breakpoints.mobileSmall
            ? '0.93rem'
            : '0.85rem'
      }}
    >
      {text}
    </Typography>
  )

  const bullet = (text: string | ReactElement) => (
    <div style={classes.bulletsDiv}>
      <Typography variant="body1" sx={Object.assign(classes.b1, classes.ml)}>
        <b>&#8226;</b>
      </Typography>
      <Typography variant="body1" sx={classes.b1}>
        {text}
      </Typography>
    </div>
  )

  const numberedBullet = (num: string, text: string | ReactElement) => (
    <div style={classes.bulletsDiv}>
      <Typography variant="body1" sx={Object.assign(classes.b1, classes.ml)}>
        {num}.
      </Typography>
      <Typography variant="body1" sx={classes.b1}>
        {text}
      </Typography>
    </div>
  )

  return (
    <Box sx={classes.mainDiv}>
      <Box
        sx={classes.sndDiv}
        style={{
          minHeight: screenDimensions.height - 220
        }}
      >
        <Typography
          variant="h1"
          sx={classes.h1}
          style={{
            fontSize:
              screenDimensions.width > breakpoints.mobileSmall
                ? '2rem'
                : '1.5rem'
          }}
        >
          {t('h1')}
        </Typography>
        <Typography variant="body1" sx={classes.b1}>
          {t('version')}
        </Typography>
        <Typography
          variant="body1"
          sx={classes.b1}
          style={{ marginBottom: '1.5rem' }}
        >
          {t('update')}
        </Typography>

        <section>
          {b2(t('terms.introduction.title'))}
          {b2(t('terms.introduction.body'))}
        </section>

        <section>{h2(t('terms.YourAgreement.title'))}</section>

        <section>
          {h3(t('terms.YourAgreement.contract.title'))}
          {b2(t('terms.YourAgreement.contract.body.1'))}
          {b2(t('terms.YourAgreement.contract.body.2'))}
        </section>
        <section>
          {h3(t('terms.YourAgreement.agreement.title'))}
          {b2(t('terms.YourAgreement.agreement.body.1'))}
        </section>
        <section>
          {h3(t('terms.YourAgreement.entering.title'))}
          {b2(t('terms.YourAgreement.entering.body.1'))}
          {b2(
            <>
              {t('terms.YourAgreement.entering.body.2.1')}
              {link(
                t('terms.YourAgreement.entering.body.2.privacy'),
                'https://kroemtech.ch/en/privacy/'
              )}
              {t('terms.YourAgreement.entering.body.2.2')}
              {link(
                t('terms.YourAgreement.entering.body.2.termsOfUse'),
                'https://kroemtech.ch/en/terms/'
              )}
              {t('terms.YourAgreement.entering.body.2.3')}
            </>
          )}
        </section>
        <section>
          {h3(t('terms.YourAgreement.service.title'))}
          {b2(t('terms.YourAgreement.service.body.1'))}
        </section>

        <section>{h2(t('terms.YourAccount.title'))}</section>

        <section>
          {h3(t('terms.YourAccount.registration.title'))}
          {b2(t('terms.YourAccount.registration.body.1'))}
          {b2(t('terms.YourAccount.registration.body.2'))}
          {numberedBullet('1', t('terms.YourAccount.registration.list.1'))}
          {numberedBullet('2', t('terms.YourAccount.registration.list.2'))}
          {numberedBullet('3', t('terms.YourAccount.registration.list.3'))}
          {numberedBullet('4', t('terms.YourAccount.registration.list.4'))}
          {b2(t('terms.YourAccount.registration.body.3'))}
        </section>
        <section>
          {h3(t('terms.YourAccount.compliance.title'))}
          {b2(t('terms.YourAccount.compliance.body.1'))}
          {b2(t('terms.YourAccount.compliance.body.2'))}
          {numberedBullet('1', t('terms.YourAccount.compliance.list.1'))}
          {numberedBullet('2', t('terms.YourAccount.compliance.list.2'))}
        </section>
        <section>
          {h3(t('terms.YourAccount.administrative.title'))}
          {b2(t('terms.YourAccount.administrative.body.1'))}
          {bullet(<>{t('terms.YourAccount.administrative.list.1')}</>)}
          {bullet(<>{t('terms.YourAccount.administrative.list.2')}</>)}
        </section>
        <section>
          {h3(t('terms.YourAccount.serviceOfferings.title'))}
          {b2(t('terms.YourAccount.serviceOfferings.body.1'))}
          {b2(t('terms.YourAccount.serviceOfferings.body.2'))}
        </section>
        <section>
          {h3(t('terms.YourAccount.usageLimitations.title'))}
          {b2(t('terms.YourAccount.usageLimitations.body.1'))}
        </section>
        <section>
          {h3(t('terms.YourAccount.subscription.title'))}
          {b2(t('terms.YourAccount.subscription.body.1'))}
        </section>

        <section>{h2(t('terms.FeesForUsing.title'))}</section>

        <section>
          {h3(t('terms.FeesForUsing.PaidUse.title'))}
          {b2(t('terms.FeesForUsing.PaidUse.body.1'))}
        </section>
        <section>
          {h3(t('terms.FeesForUsing.Payment.title'))}
          {b2(t('terms.FeesForUsing.Payment.body.1'))}
        </section>
        <section>
          {h3(t('terms.FeesForUsing.OverdueCharges.title'))}
          {b2(t('terms.FeesForUsing.OverdueCharges.body.1'))}
        </section>
        <section>
          {h3(t('terms.FeesForUsing.Suspension.title'))}
          {b2(t('terms.FeesForUsing.Suspension.body.1'))}
        </section>
        <section>
          {h3(t('terms.FeesForUsing.Taxes.title'))}
          {b2(t('terms.FeesForUsing.Taxes.body.1'))}
          {b2(t('terms.FeesForUsing.Taxes.body.2'))}
          {b2(t('terms.FeesForUsing.Taxes.body.3'))}
        </section>
        <section>
          {h3(t('terms.FeesForUsing.PaymentInfo.title'))}
          {b2(t('terms.FeesForUsing.PaymentInfo.body.1'))}
        </section>
        <section>
          {h3(t('terms.FeesForUsing.PaymentConditions.title'))}
          {b2(t('terms.FeesForUsing.PaymentConditions.body.1'))}
        </section>

        <section>{h2(t('terms.Rights.title'))}</section>

        <section>
          {h3(t('terms.Rights.Content.title'))}
          {b2(t('terms.Rights.Content.body.1'))}
        </section>
        <section>
          {h3(t('terms.Rights.KroemtechContent.title'))}
          {b2(t('terms.Rights.KroemtechContent.body.1'))}
        </section>
        <section>
          {h3(t('terms.Rights.YourContent.title'))}
          {b2(t('terms.Rights.YourContent.body.1'))}
        </section>
        <section>
          {h3(t('terms.Rights.RemovalOfContent.title'))}
          {b2(t('terms.Rights.RemovalOfContent.body.1'))}
        </section>
        <section>
          {h3(t('terms.Rights.Backup.title'))}
          {b2(t('terms.Rights.Backup.body.1'))}
        </section>

        <section>
          {h2(t('terms.YourResponsibilities.title'))}
          {b2(t('terms.YourResponsibilities.body.1'))}
          {b2(t('terms.YourResponsibilities.body.2'))}
          {numberedBullet('1', t('terms.YourResponsibilities.list.1'))}
          {numberedBullet('2', t('terms.YourResponsibilities.list.2'))}
          {numberedBullet('3', t('terms.YourResponsibilities.list.3'))}
          {numberedBullet('4', t('terms.YourResponsibilities.list.4'))}
          {numberedBullet('5', t('terms.YourResponsibilities.list.5'))}
          {numberedBullet('6', t('terms.YourResponsibilities.list.6'))}
          {b2(t('terms.YourResponsibilities.body.3'))}
        </section>

        <section>{h2(t('terms.LicenseAndRestrictions.title'))}</section>

        <section>
          {h3(t('terms.LicenseAndRestrictions.LicenseFromKroemtech.title'))}
          {b2(t('terms.LicenseAndRestrictions.LicenseFromKroemtech.body.1'))}
        </section>
        <section>
          {h3(t('terms.LicenseAndRestrictions.restrictions.title'))}
          {b2(t('terms.LicenseAndRestrictions.restrictions.body.1'))}
          {bullet(<>{t('terms.LicenseAndRestrictions.restrictions.list.1')}</>)}
          {bullet(<>{t('terms.LicenseAndRestrictions.restrictions.list.2')}</>)}
          {bullet(<>{t('terms.LicenseAndRestrictions.restrictions.list.3')}</>)}
          {b2(t('terms.LicenseAndRestrictions.restrictions.body.2'))}
        </section>
        <section>
          {h3(t('terms.LicenseAndRestrictions.Hardware.title'))}
          {b2(t('terms.LicenseAndRestrictions.Hardware.body.1'))}
        </section>
        <section>
          {h3(t('terms.LicenseAndRestrictions.PropertyRights.title'))}
          {b2(t('terms.LicenseAndRestrictions.PropertyRights.body.1'))}
        </section>
        <section>
          {h3(t('terms.LicenseAndRestrictions.Installation.title'))}
          {b2(t('terms.LicenseAndRestrictions.Installation.body.1'))}
          {bullet(<>{t('terms.LicenseAndRestrictions.Installation.list.1')}</>)}
          {bullet(<>{t('terms.LicenseAndRestrictions.Installation.list.2')}</>)}
        </section>
        <section>
          {h3(t('terms.LicenseAndRestrictions.Usage.title'))}
          {b2(t('terms.LicenseAndRestrictions.Usage.body.1'))}
          {b2(t('terms.LicenseAndRestrictions.Usage.body.2'))}
        </section>
        <section>
          {h3(t('terms.LicenseAndRestrictions.Updates.title'))}
          {b2(t('terms.LicenseAndRestrictions.Updates.body.1'))}
        </section>
        <section>
          {h3(t('terms.LicenseAndRestrictions.Connectivity.title'))}
          {b2(t('terms.LicenseAndRestrictions.Connectivity.body.1'))}
        </section>
        <section>
          {h3(t('terms.LicenseAndRestrictions.IrregularStates.title'))}
          {b2(t('terms.LicenseAndRestrictions.IrregularStates.body.1'))}
        </section>

        <section>{h2(t('terms.LicenseFromYou.title'))}</section>

        <section>
          {h3(t('terms.LicenseFromYou.LicenseFromYou.title'))}
          {b2(t('terms.LicenseFromYou.LicenseFromYou.body.1'))}
          {bullet(<>{t('terms.LicenseFromYou.LicenseFromYou.list.1')}</>)}
          {bullet(<>{t('terms.LicenseFromYou.LicenseFromYou.list.2')}</>)}
          {bullet(<>{t('terms.LicenseFromYou.LicenseFromYou.list.3')}</>)}
        </section>
        <section>
          {h3(t('terms.LicenseFromYou.DataSharing.title'))}
          {b2(t('terms.LicenseFromYou.DataSharing.body.1'))}
        </section>
        <section>
          {h3(t('terms.LicenseFromYou.Feedback.title'))}
          {b2(t('terms.LicenseFromYou.Feedback.body.1'))}
        </section>

        <section>{h2(t('terms.ThirdParty.title'))}</section>

        <section>
          {h3(t('terms.ThirdParty.Subcontractors.title'))}
          {b2(t('terms.ThirdParty.Subcontractors.body.1'))}
        </section>
        <section>
          {h3(t('terms.ThirdParty.Hyperlinks.title'))}
          {b2(t('terms.ThirdParty.Hyperlinks.body.1'))}
        </section>

        <section>{h2(t('terms.LegalNotice.title'))}</section>

        <section>
          {h3(t('terms.LegalNotice.Copyright.title'))}
          {b2(t('terms.LegalNotice.Copyright.body.1'))}
        </section>
        <section>
          {h3(t('terms.LegalNotice.Trademarks.title'))}
          {b2(t('terms.LegalNotice.Trademarks.body.1'))}
        </section>
        <section>
          {h3(t('terms.LegalNotice.DataPrivacy.title'))}
          {b2(t('terms.LegalNotice.DataPrivacy.body.1'))}
        </section>

        <section>{h2(t('terms.ModificationAndTermination.title'))}</section>

        <section>
          {h3(t('terms.ModificationAndTermination.Modification.title'))}
          {b2(t('terms.ModificationAndTermination.Modification.body.1'))}
          {b2(t('terms.ModificationAndTermination.Modification.body.2'))}
        </section>
        <section>
          {h3(t('terms.ModificationAndTermination.TerminationByYou.title'))}
          {b2(t('terms.ModificationAndTermination.TerminationByYou.body.1'))}
        </section>
        <section>
          {h3(
            t('terms.ModificationAndTermination.TerminationByKroemtech.title')
          )}
          {b2(
            t('terms.ModificationAndTermination.TerminationByKroemtech.body.1')
          )}
          {numberedBullet(
            '1',
            t('terms.ModificationAndTermination.TerminationByKroemtech.list.1')
          )}
          {numberedBullet(
            '2',
            t('terms.ModificationAndTermination.TerminationByKroemtech.list.2')
          )}
          {numberedBullet(
            '3',
            t('terms.ModificationAndTermination.TerminationByKroemtech.list.3')
          )}
          {numberedBullet(
            '4',
            t('terms.ModificationAndTermination.TerminationByKroemtech.list.4')
          )}
          {b2(
            t('terms.ModificationAndTermination.TerminationByKroemtech.body.2')
          )}
        </section>
        <section>
          {h3(t('terms.ModificationAndTermination.DataExport.title'))}
          {b2(t('terms.ModificationAndTermination.DataExport.body.1'))}
        </section>
        <section>
          {h3(t('terms.ModificationAndTermination.Hardware.title'))}
          {b2(t('terms.ModificationAndTermination.Hardware.body.1'))}
        </section>

        <section>{h2(t('terms.Warranties.title'))}</section>

        <section>
          {h3(t('terms.Warranties.Service.title'))}
          {b2(t('terms.Warranties.Service.subtitle'))}
          {numberedBullet(
            '1',
            <>
              {t('terms.Warranties.Service.body.1bold')}
              {t('terms.Warranties.Service.body.1')}
            </>
          )}
          {numberedBullet(
            '2',
            <>
              {t('terms.Warranties.Service.body.2bold')}
              {t('terms.Warranties.Service.body.2')}
            </>
          )}
          {numberedBullet(
            '3',
            <>
              {t('terms.Warranties.Service.body.3bold')}
              {t('terms.Warranties.Service.body.3')}
            </>
          )}
          {numberedBullet(
            '4',
            <>
              {t('terms.Warranties.Service.body.4bold')}
              {t('terms.Warranties.Service.body.4')}
            </>
          )}
          {numberedBullet(
            '5',
            <>
              {t('terms.Warranties.Service.body.5bold')}
              {t('terms.Warranties.Service.body.5')}
            </>
          )}
        </section>
        <section>
          {h3(t('terms.Warranties.General.title'))}
          {b2(t('terms.Warranties.General.body.1'))}
        </section>

        <section>
          {h2(t('terms.LimitationOfLiability.title'))}
          {numberedBullet('1', t('terms.LimitationOfLiability.list.1'))}
          {numberedBullet('2', t('terms.LimitationOfLiability.list.2'))}
          {numberedBullet('3', t('terms.LimitationOfLiability.list.3'))}
          {numberedBullet('4', t('terms.LimitationOfLiability.list.4'))}
          <div style={{ marginLeft: '2rem' }}>
            {bullet(t('terms.LimitationOfLiability.4.1'))}
            {bullet(t('terms.LimitationOfLiability.4.2'))}
            {bullet(t('terms.LimitationOfLiability.4.3'))}
            {bullet(t('terms.LimitationOfLiability.4.4'))}
          </div>
        </section>

        <section>
          {h2(t('terms.Indemnification.title'))}
          {numberedBullet('1', t('terms.Indemnification.body.1'))}
          <div style={{ marginLeft: '2rem' }}>
            {bullet(t('terms.Indemnification.list.1'))}
            {bullet(t('terms.Indemnification.list.2'))}
            {bullet(t('terms.Indemnification.list.3'))}
            {bullet(t('terms.Indemnification.list.4'))}
          </div>
          {numberedBullet('2', t('terms.Indemnification.body.2'))}
        </section>

        <section>
          {h2(t('terms.ChangesToTermsOfService.title'))}
          {b2(t('terms.ChangesToTermsOfService.body.1'))}
          {bullet(t('terms.ChangesToTermsOfService.list.1'))}
          {bullet(t('terms.ChangesToTermsOfService.list.2'))}
          {bullet(t('terms.ChangesToTermsOfService.list.3'))}
        </section>

        <section>{h2(t('terms.Miscellaneous.title'))}</section>

        <section>
          {h3(t('terms.Miscellaneous.Scope.title'))}
          {b2(t('terms.Miscellaneous.Scope.body.1'))}
        </section>
        <section>
          {h3(t('terms.Miscellaneous.RelationshipOfTheParties.title'))}
          {b2(t('terms.Miscellaneous.RelationshipOfTheParties.body.1'))}
        </section>
        <section>
          {h3(t('terms.Miscellaneous.NoWaiver.title'))}
          {b2(t('terms.Miscellaneous.NoWaiver.body.1'))}
        </section>
        <section>
          {h3(t('terms.Miscellaneous.Severability.title'))}
          {b2(t('terms.Miscellaneous.Severability.body.1'))}
        </section>
        <section>
          {h3(t('terms.Miscellaneous.GoverningLaw.title'))}
          {b2(t('terms.Miscellaneous.GoverningLaw.body.1'))}
          {b2(t('terms.Miscellaneous.Contact.body.1'))}
        </section>
      </Box>
    </Box>
  )
}
